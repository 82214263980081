import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FinancingService } from 'src/app/core/services/financing/financing.service';

@Component({
  selector: 'app-upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.scss']
})
export class UploadDocumentModalComponent implements OnInit {
  uploadForm!: FormGroup;
  selectedFile?: File;
  preview!: string;
  previewIsImage: boolean = false;
  nameFile?: string;
  upload: boolean = false;
  uploading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<UploadDocumentModalComponent>,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private financingService: FinancingService,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: string; idFinancing: string 
} 
  ) {}

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      comment: [''],
      document: ['']
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  truncateFileName(fileName: string, maxLength: number = 30): string {
    return fileName.length > maxLength
      ? fileName.substring(0, maxLength) + ' [...]'
      : fileName;
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameFile = this.selectedFile!.name;

    const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    if (!allowedTypes.includes(this.selectedFile!.type)) {
      alert('Formato no permitido. Solo se aceptan PNG, JPG y PDF.');
      this.resetFileSelection();
      return;
    }

    this.convertFileBase64(this.selectedFile!).then((img: any) => {
      this.preview = img.base;
      this.previewIsImage = this.preview.includes('image');
    });
    this.upload = true;
  }

  resetFileSelection() {
    this.selectedFile = undefined;
    this.nameFile = undefined;
    this.upload = false;
  }

  async convertFileBase64(file: File) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({ base: reader.result });
    });
  }

  onSubmit() {
    if (this.selectedFile) {
      const description = this.uploadForm.get('comment')?.value;
      this.uploading = true;

      const uploadObservable = this.data.type === 'signers'
        ? this.financingService.uploadSigningDocuments(this.data.idFinancing, this.selectedFile, description)
        : this.financingService.uploadDocument(this.data.idFinancing, this.selectedFile, description);
  
      uploadObservable.subscribe({
        next: (response) => {
          this.uploading = false;
  
          const newDocument = {
            file: this.selectedFile,
            codeOperation: this.nameFile,
            state: 'Pendiente',
            date: new Date(),
            comment: description,
            previewUrl: URL.createObjectURL(this.selectedFile!)
          };
  
          this.dialogRef.close(newDocument);
          console.log(response);
        },
        error: (err) => {
          this.uploading = false;
          alert('Error al subir el documento: ' + err.message);
        }
      });
    }
  }
  
}
