<div class="login-page">
    <div class="left-container">
      <img src="../../../../assets/logos/logo.svg" alt="" />
      <div class="user-form">
        <div class="title">
          <h2>DENARIUS</h2>
          <h1>Backoffice</h1>
        </div>
        <form class="form" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()" (keydown.enter)="onSubmit()">
          <div class="individual">
            <label>USUARIO</label>
            <input placeholder="Nombre de usuario" type="text" formControlName="username" #username/>
          </div>
          <div class="individual">
            <label for="">CONTRASEÑA</label>
            <input [type]="isOpen ? 'password' : 'text'" formControlName="password" #password/>
            <img *ngIf="isOpen" (click)="toggleEye()" src="../../../../assets/img/svg/Eye.svg" alt="">
            <img *ngIf="!isOpen" (click)="toggleEye()" src="../../../../assets/img/svg/Eye-Off.svg" alt="">
          </div>
          <div class="checkbox">
            <input type="checkbox" />
            <p>Recordar</p>
          </div>
          <div *ngIf="errorMessage" class="error-message">
            <p>{{ errorMessage }}</p>
          </div>
          <button [disabled]="!loginForm.valid" class="gold-button" (click)="onSubmit()"  aria-label="register_btn" type="submit" [disabled]="!loginForm.valid">ACCEDER</button>
        </form>
      </div>
    </div>
    <div class="image-background"></div>
  </div>
  