<form [formGroup]="uploadForm" class="form-upload" (ngSubmit)="onSubmit()">
  <div class="button-container">
    <input type="file" (change)="onFileSelected($event)" accept="image/*, application/pdf" id="fileInput">
    <label for="fileInput" class="button-upload">
      <span>{{ upload ? 'Cambiar Documento' : 'Seleccionar Documento' }}</span>
      <span>PNG, PDF, JPG</span>
    </label>
  </div>
  <div class="img-preview" *ngIf="preview">
    <img *ngIf="previewIsImage" [src]="preview" alt="Previsualización del documento" />
    <mat-icon *ngIf="!previewIsImage">picture_as_pdf</mat-icon>
    <p>{{ truncateFileName(nameFile!) }}</p>
  </div>
  <div class="form-group">
    <label for="comment">Descripción</label>
    <input formControlName="comment" id="comment">
  </div>
  <div class="buttons">
  <button class="stroked-gold-button" (click)="onCancel()">Cancelar</button>
  <button type="submit" class="gold-button" [disabled]="!selectedFile || uploading">Subir Documento</button>
</div>
</form>
