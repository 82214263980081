export class UserData{
    id?: string
    createdAt?: string
    fullName?: string
    name!:string;
    surname?:string;
    surname1?:string;
    surname2?:string;
    birthdate?:Date;
    documentType?:string;
    gender?: string;
    identifier!:string;
    password?:number;
    rePassword?:number;
    email?: string;
    prefix?: string;
    phone!: number;
    nationality?: string;
    address?: Address;
    addresses:any;
    //FIN de Onboarding
    alias?:string
    balance!: number;
    incomes!: number;
    expenses!: number;
    cards?: Card[];
    accounts!: AccountsList[]; 
    segmentId?: string;
    productId?: string;
    status?: string;
    kyc?: string;
    verifyId?: string;
    state?: string;
}

export interface UserCreate{
    id: string;
    state: string;
}

export interface Address{
    via: string;
    street: string;
    number: number;
    flat: string;
    door: string;
    other: string;
    city: string;
    zip: number;
    country: string
    
}

export interface AccountsList {
    name?: string,
    account?: string,
    id: string,
    iban: string,
    alias:string,
    swift:string,
    currency:string,
    openingDate: string,
    lastOperationDate: string,
    balance:number,
    availableBalance: number,
    state:string,
}

export interface Card {
    name?: string,
    account?: string,
    id: string,
    iban: string,
    balance:number,
    availableBalance: number
}

export interface Profile {
    address: AddressProfile,
    birthdate?: string,
    documentType?: string,
    email?: string,
    gender?: string,
    id?: string,
    identifier?: string,
    name?: string,
    nationality?: string,
    phone?: string,
    prefix?: string,
    productId?: string,
    segmentId?: string,
    surname1?: string,
    addresses: any;
}

export interface AddressProfile{
    city?: string,
    country?: string,
    door?: string,
    flat?: string,
    number?: string,
    other?: string,
    street?: string,
    via?: string,
    zip?: string

}

export enum BusinessRole {
    REPRESENTATIVE = 'Apoderado',
    PARTNER = 'Socio',
    ADMINISTRATOR = 'Administrador'
}

