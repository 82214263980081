import { DatePipe, formatDate, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { downloadFile } from 'src/app/core/extensions/downloadFile';
import { formatAsPercentage, formatDateOnlyDay, setCurrencyFormat } from 'src/app/core/extensions/functions';
import { stateFinancing } from 'src/app/core/extensions/stateFinancing.extension';
import { getFinancingUserType } from 'src/app/core/extensions/userRole.extension';
import { Contract, Products } from 'src/app/core/models/financing/financing';
import { FinancingService } from 'src/app/core/services/financing/financing.service';
import { ModalStatesComponent } from 'src/app/shared/components/modals/modal-states/modal-states.component';
registerLocaleData(localeEs, 'es');

export enum FINANCING_STATES {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
  COMPLETED = 'COMPLETED'
}

@Component({
  selector: 'app-financing-detail',
  templateUrl: './financing-detail.component.html',
  styleUrl: './financing-detail.component.scss',
})
export class FinancingDetailComponent {
  public personalInfo!: any;
  public operationInfo!: any;
  public infoComercial: any = [];
  public salesPoint: any = [];
  public infoClientFreelance: any = [];
  public infoClientCompany: any = [];
  public infoClient!: any;
  public infoAddress: any = [];
  public infoShippingAddress: any = [];
  public products: Products[] = [];
  public conditions: any = [];
  public resume: any = [];

  public isCompany!: boolean;

  public loader: boolean = true;

  public offset: number = 4;
  public file!: Blob;
  public identifier!: string;

  public selectedState!: string;
  public previousState!: string;
  public stateSelectList!: any;

public showAlert: boolean = false;
public alertMessage: string = '';
public alertStatus: string = 'ko';

  formatAsPercentage = formatAsPercentage;
  stateFinancing = stateFinancing;
  formatDate = formatDate;
  formatDateOnlyDay = formatDateOnlyDay;
  downloadFile = downloadFile;

  @Input() userData!: any;

  public displayedColumns: string[] = [
    'contract',
    'status',
    'date',
    'download',
  ];

  public dataContract!: MatTableDataSource<Contract>;

  hideAlert() {
    this.showAlert = false;
  }

  public financingStates = [
    {
      value: FINANCING_STATES.PENDING,
      availableStates: [FINANCING_STATES.PROCESSING, FINANCING_STATES.CANCELLED]
    },
    {
      value: FINANCING_STATES.PROCESSING,
      availableStates: [FINANCING_STATES.APPROVED, FINANCING_STATES.CANCELLED, FINANCING_STATES.DENIED]
    },
    {
      value: FINANCING_STATES.APPROVED,
      availableStates: [FINANCING_STATES.CANCELLED, FINANCING_STATES.COMPLETED]
    },
    {
      value: FINANCING_STATES.CANCELLED,
      availableStates: [FINANCING_STATES.PROCESSING]
    },
    {
      value: FINANCING_STATES.DENIED,
      availableStates: [FINANCING_STATES.PROCESSING]
    },
    {
      value: FINANCING_STATES.COMPLETED,
      availableStates: [FINANCING_STATES.CANCELLED]
    }
  ];
  private stateActions: Record<string, () => void> = {
    [FINANCING_STATES.PROCESSING]: () => this.changeOperationState('PROCESSING'),
    [FINANCING_STATES.PENDING]: () => this.changeOperationState('PENDING'),
    [FINANCING_STATES.CANCELLED]: () => this.changeOperationState('CANCELLED'),
    [FINANCING_STATES.APPROVED]: () => this.changeOperationState('APPROVED'),
    [FINANCING_STATES.DENIED]: () => this.changeOperationState('DENIED'),
    [FINANCING_STATES.COMPLETED]: () => this.changeOperationState('COMPLETED')
};

  constructor(
    private financingService: FinancingService,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
  }

  canEdit: boolean = false;

  ngOnInit() {
    if (this.userData) {
      this.operationInfo = this.userData;
      this.personalInfo = this.userData.customer;
      this.products = this.operationInfo.products;
      this.loader = false;
      this.selectedState = this.operationInfo.state;
      this.previousState = this.selectedState;


      this.getStateSelectList()
      this.getFinancing();
      this.conditions = {
        title: 'Condiciones de financiación',
        icon: 'checklist',
        data: [
          {
            key: 'type',
            name: 'Tipo',
            value: this.operationInfo.type || '-',
            editable: false,
          },
          {
            key: 'downPayment',
            name: 'Entrada Inicial',
            value: setCurrencyFormat(this.operationInfo.downPayment) || '-',
            editable: false,
          },
          {
            key: 'firstPayment',
            name:  'Fecha de Primer Pago',
            value: formatDateOnlyDay(this.userData.installments[0].date )|| '-',
            editable: this.operationInfo.state === FINANCING_STATES.APPROVED,
            isDateFuture: true,
          },
          {
            key: 'monthsTerm',
            name: 'Plazo',
            value: `${this.operationInfo.monthsTerm} meses` || '-',
            editable: false,
          },
          {
            key: 'gracePeriod',
            name: 'Carencia',
            value: `${this.operationInfo.gracePeriod} meses` || '-',
            editable: false,
          },
          {
            key: 'insurance',
            name: 'Prima Seguro Mensual',
            value: setCurrencyFormat(this.operationInfo.insurance) || '-',
            editable: false,
          },
          {
            key: 'financedCapital',
            name: 'Importe financiado',
            value: setCurrencyFormat(this.operationInfo.financedCapital) || '-',
            editable: false,
          },
          {
            key: 'tin',
            name: 'TIN',
            value: formatAsPercentage(this.operationInfo.tin) || '-',
            editable: false,
          },
          {
            key: 'tae',
            name: 'TAE',
            value: formatAsPercentage(this.operationInfo.tae) || '-',
            editable: false,
          },
        ],
      };

      this.resume = {
        title: 'Resultado operación',
        icon: 'calculate',
        data: [
          {
            key: 'gracePeriod',
            name:
              this.operationInfo.gracePeriod === 0
                ? 'Cuota Carencia'
                : `Cuota Carencia (x${this.operationInfo.gracePeriod} meses)`,

            value:
              this.operationInfo.gracePeriod !== 0
                ? setCurrencyFormat(this.operationInfo.installments[0].payment)
                : '-',
          },
          {
            key: 'installments',
            name: 'Cuota Regular',
            value:
              setCurrencyFormat(
                this.operationInfo.installments[
                  this.operationInfo.installments.length - 2
                ].payment || '-'
              ) || '-',
            editable: false,
          },
          {
            key: 'openingCommission',
            name: `Comisión apertura (${formatAsPercentage(
              this.operationInfo.openingCommission
            )})`,
            value:
              setCurrencyFormat(
                (
                  this.operationInfo.totalAmount *
                  this.operationInfo.openingCommission
                ).toString()
              ) || '-',
            editable: false,
          },
          {
            key: 'residualValue',
            name: 'Última cuota',
            value:
              setCurrencyFormat(
                this.operationInfo.installments[
                  this.operationInfo.installments.length - 1
                ].payment
              ) || '-',
            editable: false,
          },
        ],
      };

      // this.dataContract = new MatTableDataSource([
      //   {
      //     contract: `contrato_leasing_${this.operationInfo.codeOperation}.pdf`,
      //     status: 'Firmado',
      //     date: formatDate(this.operationInfo.date),
      //     download: 'Descargar',
      //   },
      // ]);
    }
  }

  getFinancing() {
    if (this.personalInfo) {
      this.isCompany = getFinancingUserType(this.personalInfo.identifier)
      this.loader = false;
    }

    this.infoComercial = {
      title: 'Datos comercial',
      icon: 'store',
      data: [
        {
          key: 'name',
          name: 'Nombre comercial',
          value: `${this.operationInfo?.commercial?.name || '-'} ${this.operationInfo?.commercial?.surname || '-'
            }`,
          editable: false,
        },
        {
          key: 'email',
          name: 'Email',
          value: this.operationInfo?.commercial?.email || '-',
          editable: false,
        },
          {
            key: 'phone',
            name: 'Teléfono',
            value: this.operationInfo?.commercial?.phone,
            editable: false,
          }
      ].filter(item =>
        (item.key !== 'phone' || item.value)
      )
    };

    this.salesPoint = {
      title: 'Datos Punto Venta',
      icon: 'store',
      data: [
        {
          key: 'name',
          name: 'Razón social',
          value: `${this.operationInfo?.salesPoint?.name}`,
          editable: false,
        },
        {
          key: 'email',
          name: 'Email',
          value: this.operationInfo?.salesPoint?.email || '-',
          editable: false,
        },
          {
            key: 'phone',
            name: 'Teléfono',
            value: this.operationInfo?.salesPoint?.phone,
            editable: false,
          },
          {
            key: 'cif',
            name: 'CIF',
            value: this.operationInfo?.salesPoint?.identifier,
            editable: false,
          },
          {
            key: 'address',
            name: 'Dirección',
            value:`${this.operationInfo?.salesPoint?.address.street} ${this.operationInfo?.salesPoint?.address.city} ${this.operationInfo?.salesPoint?.address.zip} ${this.operationInfo?.salesPoint?.address.province}`,
            editable: false,
          },
      ].filter(item =>
        (item.key !== 'phone' || item.value) &&
        (item.key !== 'cif' || item.value) &&
        (item.key !== 'address' || item.value) &&
        (item.key !== 'nameCommercial' || item.value)
      )
    };

    if (this.personalInfo) {
      this.infoClient = {
        title: 'Datos Cliente',
        icon: 'face',
        data: [
          {
            key: 'type',
            name: 'Tipo',
            value: this.isCompany ? 'Empresa': 'Autónomo',
            editable: false,
          },
          {
            key: 'name',
            name:  this.isCompany ? 'Razón social' :'Nombre',
            value: `${this.personalInfo?.name || '-'}`,
            editable: this.isEditable(this.operationInfo.state),
          },
          {
            key: 'email',
            name: 'Email',
            value: `${this.personalInfo?.email || '-'}`,
            editable: this.isEditable(this.operationInfo.state),
          },
          {
            key: 'phone',
            name: 'Teléfono',
            value: `${this.personalInfo?.phone || '-'}`,
            editable: this.isEditable(this.operationInfo.state),
          },
          {
            key: 'identifier',
            name: this.isCompany ? 'CIF' : 'DNI',
            value: `${this.personalInfo?.identifier || '-'}`,
            editable: this.isEditable(this.operationInfo.state),
          },
          {
            key: 'iban',
            name: 'IBAN',
            value: `${this.personalInfo?.iban || '-'}`,
            editable: this.isEditable(this.operationInfo.state),
          },
        ],
      };
    }

    this.infoAddress = {
      title: 'Dirección Facturación',
      icon: 'home',
      data: [
        {
          key: 'address',
          name: 'Dirección',
          value: this.personalInfo.address.street || '-',
          editable: this.isEditable(this.operationInfo.state),
        },
        {
          key: 'city',
          name: 'Población',
          value: this.personalInfo.address.city || '-',
          editable: this.isEditable(this.operationInfo.state),
        },
        {
          key: 'province',
          name: 'Provincia',
          value: this.personalInfo.address.province || '-',
          editable: this.isEditable(this.operationInfo.state),
        },
        {
          key: 'zip',
          name: 'CP',
          value: this.personalInfo.address.zip || '-',
          editable: this.isEditable(this.operationInfo.state),
        },
        {
          key: 'invoicing-email',
          name: 'Email Facturación',
          value: `${this.personalInfo?.email || '-'}`,
          editable: this.isEditable(this.operationInfo.state),
        },
        {
          key: 'invoicing-phone',
          name: 'Teléfono Facturación',
          value: `${this.personalInfo?.phone || '-'}`,
          editable: this.isEditable(this.operationInfo.state),
        },
        ...(this.operationInfo.state === FINANCING_STATES.APPROVED || this.operationInfo.state === FINANCING_STATES.COMPLETED
          ? [
              {
                  key: 'first-payment',
                  name: 'Fecha Primer Cobro',
                  value: formatDateOnlyDay(this.userData.installments[0].date) || '-',
                  editable: false,
              },
            ]
          : []),
      ],
    };
    this.infoShippingAddress = {
      title: 'Dirección Envío',
      icon: 'local_shipping',
      data: [
        {
          key: 'address',
          name: 'Dirección',
          value: this.personalInfo.shippingAddress.street || '-',
          editable: this.isEditable(this.operationInfo.state),
        },
        {
          key: 'city',
          name: 'Población',
          value: this.personalInfo.shippingAddress.city || '-',
          editable: this.isEditable(this.operationInfo.state),
        },
        {
          key: 'province',
          name: 'Provincia',
          value: this.personalInfo.shippingAddress.province || '-',
          editable: this.isEditable(this.operationInfo.state),
        },
        {
          key: 'zip',
          name: 'CP',
          value: this.personalInfo.shippingAddress.zip || '-',
          editable: this.isEditable(this.operationInfo.state),
        },
      ],
    };

    this.cdr.detectChanges();
  }

  getProductInfo(product: any) {
    return {
      icon: '',
      title: '',
      data: [
        {
          key: 'description',
          name: `x${product.quantity} ${product.name}`,
          value: setCurrencyFormat(product.unitPrice) || '-',
          editable: false,
        },
        {
          key: 'brand',
          name: 'Marca',
          value: product.brand || '-',
          editable: false,
        },
        {
          key: 'model',
          name: 'Modelo',
          value: product.model || '-',
          editable: false,
        },
      ],
    };
  }

  showAlertMessage(message: string, status: string) {

    this.alertMessage = message;
    this.alertStatus = status;
    this.showAlert = false; 
    setTimeout(() => (this.showAlert = true), 0); 
}

  getStateSelectList(){
    this. stateSelectList = this.financingStates.find(item => item.value === this.selectedState)
  }

  private changeOperationState(state: string) {

    if (state === FINANCING_STATES.APPROVED || state === FINANCING_STATES.COMPLETED) {
        if (!this.validateApprovedAndCompletedConditions()) {
            this.showAlertMessage(
                'No se puede cambiar al estado seleccionado. Verifique que los datos de facturación estén completos y válidos.',
                'ko'
            );

            this.selectedState = this.previousState;
            this.operationInfo.state = this.previousState;
            return;
        }
    }

    this.financingService.changeStateOperation(this.operationInfo.id, state).subscribe({
        next: (res) => {
            this.operationInfo.state = state;
            const formattedState = stateFinancing(state);
            this.getFinancing(); 
            this.showAlertMessage(`Operación cambiada a ${formattedState}`, 'ok');
        },
        error: (err) => {
            console.error('Error:', err);

            this.selectedState = this.previousState;
            this.getFinancing(); 
            this.operationInfo.state = this.previousState;

            const formattedState = stateFinancing(state);
            this.showAlertMessage(`Error al cambiar el estado de la operación a ${formattedState}`, 'ko');
        },
    });
}

private validateApprovedAndCompletedConditions(): boolean {
  const { email, phone, iban } = this.personalInfo;

  return (
      email && email !== '-' && 
      phone && phone !== '-' && 
      iban && iban !== '-' 
  );
}

  downloadContract() {
    this.financingService.getContract(this.operationInfo.id).subscribe((res) => {
      this.file = res;

      downloadFile(
        this.file,
        `contrato_arrendamiento_${this.operationInfo.codeOperation}.pdf`
      );
    });
  }

  goBackToTable() {
    this.router.navigate(['/contratacion/renting']);
  }

  openStateChangeModal(item: any): void {
    let newState = {
      state: item.value,
      function:() => this.stateActions[item.value]()
      
    } 
    const dialogRef = this.dialog.open(ModalStatesComponent, {
      data: newState,
    });

    dialogRef.afterClosed().subscribe(confirmado => {
      if (confirmado) {
        this.previousState = this.selectedState;
        this.getStateSelectList()

      } else {
        this.selectedState = this.previousState
      }
    })
  }

  showEdit(item: any): void {
    item.editing = true;
  }

  onEditField(editedField: any, object: any) {
    const sectionMappings: Record<'client' | 'address' | 'shippingAddress', any[]> = {
        client: this.infoClient.data,
        address: this.infoAddress.data,
        shippingAddress: this.infoShippingAddress.data,
    };

    const section = sectionMappings[editedField.sectionKey as 'client' | 'address' | 'shippingAddress'];
    if (section) {
        const targetField = section.find((field: any) => field.key === editedField.key);

        if (targetField) {
            targetField.value = editedField.value;
        }
    }
    this.saveClientDetails(editedField, object);
}

  hideEdit(item: any): void {
    item.editing = false;
  }

  isEditable(state: string): boolean {
    return (state === FINANCING_STATES.APPROVED || state === FINANCING_STATES.PROCESSING);
  }

  saveClientDetails(editedField:any, object:any) {
    const isCompany = this.isCompany;

    const updatedFieldsCompany = {
        name: this.infoClient.data.find((field: any) => field.key === 'name')?.value || '',
        surname: null,
        identifier: this.infoClient.data.find((field: any) => field.key === 'identifier')?.value || '',
        iban: this.infoClient.data.find((field: any) => field.key === 'iban')?.value || '',
        address: {
            street: this.infoAddress.data.find((field: any) => field.key === 'address')?.value || '',
            city: this.infoAddress.data.find((field: any) => field.key === 'city')?.value || '',
            province: this.infoAddress.data.find((field: any) => field.key === 'province')?.value || '',
            zip: this.infoAddress.data.find((field: any) => field.key === 'zip')?.value || '',
        },
        shippingAddress: {
            street: this.infoShippingAddress.data.find((field: any) => field.key === 'address')?.value || '',
            city: this.infoShippingAddress.data.find((field: any) => field.key === 'city')?.value || '',
            province: this.infoShippingAddress.data.find((field: any) => field.key === 'province')?.value || '',
            zip: this.infoShippingAddress.data.find((field: any) => field.key === 'zip')?.value || '',
        },
        contact: {
            email: this.infoClient.data.find((field: any) => field.key === 'email')?.value || '',
            phone: this.infoClient.data.find((field: any) => field.key === 'phone')?.value || '',
        }
    };

    const updatedFieldsAutonomous = {
        name: this.infoClient.data.find((field: any) => field.key === 'name')?.value || '',
        surname: this.infoClient.data.find((field: any) => field.key === 'surname')?.value || '',
        identifier: this.infoClient.data.find((field: any) => field.key === 'identifier')?.value || '',
        phone: this.infoClient.data.find((field: any) => field.key === 'phone')?.value || '',
        email: this.infoClient.data.find((field: any) => field.key === 'email')?.value || '',
        iban: this.infoClient.data.find((field: any) => field.key === 'iban')?.value || '',
        address: {
            street: this.infoAddress.data.find((field: any) => field.key === 'address')?.value || '',
            city: this.infoAddress.data.find((field: any) => field.key === 'city')?.value || '',
            province: this.infoAddress.data.find((field: any) => field.key === 'province')?.value || '',
            zip: this.infoAddress.data.find((field: any) => field.key === 'zip')?.value || '',
        },
        shippingAddress: {
            street: this.infoShippingAddress.data.find((field: any) => field.key === 'address')?.value || '',
            city: this.infoShippingAddress.data.find((field: any) => field.key === 'city')?.value || '',
            province: this.infoShippingAddress.data.find((field: any) => field.key === 'province')?.value || '',
            zip: this.infoShippingAddress.data.find((field: any) => field.key === 'zip')?.value || '',
        }
    };

    const updatedFields = isCompany ? updatedFieldsCompany : updatedFieldsAutonomous;
    const customerType = isCompany ? 'company' : 'autonomous';

    this.financingService.editCustomer(customerType, this.personalInfo.id, updatedFields).subscribe({
        next: (response) => {
            this.showAlertMessage('Datos del cliente actualizados con éxito.', 'ok');
            object[editedField.key]=  editedField.value
            
        },
        error: (error) => {
            console.error('Error al actualizar los datos del cliente:', error);
            this.showAlertMessage('Error al actualizar los datos del cliente.', 'ko');
        },
    });
}

}
