import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { formatExpiration, statusCard, typeCard } from 'src/app/core/extensions/cards.extension';
import { setCurrencyFormat } from 'src/app/core/extensions/functions';
import { DocumentFile } from 'src/app/core/models/documents/documents';
import { CardService } from 'src/app/core/services/cards/cards.service';
import { DocumentsService } from 'src/app/core/services/documents/documents.service';
import { BusinessService } from 'src/app/core/services/user/business.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { CardCancelComponent } from 'src/app/modules/cards/card-cancel/card-cancel.component';

@Component({
  selector: 'app-cards-detail',
  templateUrl: './cards-detail.component.html',
  styleUrl: './cards-detail.component.scss'
})
export class CardsDetailComponent {

  @Input() accountId!: string;
  @Input() cardId!: string;
  public userId!: string;
  public dataCard: any;
  public hiddenBtn!: boolean;
  public showPin!: boolean;
  public hiddenCancel!: boolean;
  public hiddenAlias!: boolean;

  public address!: string;


  public showAlert: boolean = false;
  public alertMessage!: string;
  public alertStatus!: string;

  public pin!: string;
  public filePreview!: string | null;
  public isPdfVisible: boolean = false;

  filteredDocuments: Partial<DocumentFile>[] = [];
  documents: DocumentFile[] = [];

  public count = Math.floor(980 / 40);

  public userData: any;

  public infoTable: any;
  public infoLimits: any;
  public isDataLoaded: boolean = false;

  setCurrencyFormat = setCurrencyFormat;

  @Output() detailEmit: EventEmitter<boolean> = new EventEmitter<boolean>;


  statusCard = statusCard;
  typeCard = typeCard;
  formatExpiration = formatExpiration

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private dataDetail: CardService,
    private dataIndividualAddress: UserService,
    private activate: CardService,
    private routeTable: Router,
    private pinService: CardService,
    private dataCompanyAdress: BusinessService,
    private dmsService: DocumentsService
  ) {}

  ngOnInit() {
    console.log(this.userData)
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get('id')!;
      console.log(this.userId);
    });

    this.getCard();
  }

  getCard() {
    this.dataDetail.getCard(this.userId, this.accountId, this.cardId)
      .subscribe((res) => {
        this.dataCard = res; 
        console.log(this.dataCard)
        const role = localStorage.getItem('type');
        if(role === 'Individual'){
          this.dataIndividualAddress.getUser(this.userId).subscribe((addressRes) => {
            let fullAddress = "";
            console.log(addressRes) 
            if (addressRes && addressRes.addresses && Array.isArray(addressRes.addresses)) {
              const data = addressRes.addresses;
              if (data.length > 0) {
                fullAddress = `${data[0].via} ${data[0].street} ${data[0].number}, ${data[0].flat} ${data[0].door}. ${data[0].other}, ${data[0].zip} ${data[0].city} ${data[0].country}`;
              }
            }
            this.infoTable = {
              icon: "",
              title: "",
              data: [
                {
                  key: 'name',
                  name: 'Nombre',
                  value: this.dataCard.name,
                  editable: false,
                  editing: false
                },
                {
                  key: 'account',
                  name: 'Número de cuenta asociada',
                  value: this.dataCard.account.iban,
                  editable: false,
                  editing: false
                },
                {
                  key: 'maskedPan',
                  name: 'Número de la tarjeta',
                  value: this.dataCard?.maskedPAN ? this.dataCard.maskedPAN : 'No disponible',
                  editable: false,
                  editing: false
                },
                {
                  key: 'type',
                  name: 'Tipo',
                  value: this.dataCard.type === 'DEBIT' ? 'Débito Física' : this.dataCard.type,
                  editable: false,
                  editing: false
                },
                {
                  key: 'alias',
                  name: 'Alias',
                  value: this.dataCard.alias ? this.dataCard.alias : 'Sin alias',
                  editable: true,
                  editing: false
                },
                {
                  key: 'address',
                  name: 'Dirección',
                  value: fullAddress,
                  editable: false,
                  editing: false
                },
                {
                  key: 'expiration',
                  name: 'Fecha de caducidad',
                  value: formatExpiration(this.dataCard.expiration),
                  editable: false,
                  editing: false
                }
/*                 {
                  key: 'cvv',
                  name: 'CVV',
                  value: "consultar CVV",
                  editable: false,
                  editing: false
                }, */
              ]
            };
            this.infoLimits = {
              icon: "",
              title: "",
              data: [
                {
                  key: 'limit-daily',
                  name: 'Límite diario de gasto',
                  value: setCurrencyFormat(this.dataCard.limits.purchase.daily),
                  editable: false,
                  editing: false
                },
                {
                  key: 'limit-atm-daily',
                  name: 'Límite diario de retirada en cajero',
                  value: setCurrencyFormat(this.dataCard.limits.atm.daily),
                  editable: false,
                  editing: false
                },
                {
                  key: 'physical-payments',
                  name: 'Pagos físicos',
                  value: "Sí",
                  editable: false,
                  editing: false
                },
                {
                  key: 'online-payments',
                  name: 'Pagos online',
                  value: "Sí",
                  editable: false,
                  editing: false
                },
                {
                  key: 'atm-withdrawal',
                  name: 'Retirada en cajeros',
                  value: "Sí",
                  editable: false,
                  editing: false
                },
              ]
            }
            this.isDataLoaded = true;
          });
        } else if(role === 'Empresa'){
          this.dataCompanyAdress.getCompany(this.userId).subscribe((addressRes) => {
            let fullAddress = "";
            if (addressRes && addressRes.addresses && Array.isArray(addressRes.addresses)) {
              const data = addressRes.addresses;
              if (data.length > 0) {
                fullAddress = `${data[0].via} ${data[0].street} ${data[0].number}, ${data[0].flat} ${data[0].door}. ${data[0].other}, ${data[0].zip} ${data[0].city} ${data[0].country}`;
              }
            }
            this.infoTable = {
              icon: "",
              title: "",
              data: [
                {
                  key: 'name',
                  name: 'Nombre',
                  value: this.dataCard.name,
                  editable: false,
                  editing: false
                },
                {
                  key: 'account',
                  name: 'Número de cuenta asociada',
                  value: this.dataCard.account.iban,
                  editable: false,
                  editing: false
                },
                {
                  key: 'maskedPan',
                  name: 'Número de la tarjeta',
                  value: this.dataCard?.maskedPAN !== undefined ? this.dataCard.maskedPAN || 'No disponible' : 'No disponible',
                  editable: false,
                  editing: false
                },
                {
                  key: 'type',
                  name: 'Tipo',
                  value: this.dataCard.type === 'DEBIT' ? 'Débito Física' : this.dataCard.type,
                  editable: false,
                  editing: false
                },
                {
                  key: 'alias',
                  name: 'Alias',
                  value: this.dataCard.alias ? this.dataCard.alias : 'Sin alias',
                  editable: true,
                  editing: false
                },
                {
                  key: 'address',
                  name: 'Dirección',
                  value: fullAddress,
                  editable: false,
                  editing: false
                },
                {
                  key: 'expiration',
                  name: 'Fecha de caducidad',
                  value: formatExpiration(this.dataCard.expiration),
                  editable: false,
                  editing: false
                },
/*                 {
                  key: 'cvv',
                  name: 'CVV',
                  value: "consultar CVV",
                  editable: false,
                  editing: false
                }, */
              ]
            };
            this.infoLimits = {
              icon: "",
              title: "",
              data: [
                {
                  key: 'limit-daily',
                  name: 'Límite diario de gasto',
                  value: setCurrencyFormat(this.dataCard.limits.purchase.daily),
                  editable: false,
                  editing: false
                },
                {
                  key: 'limit-atm-daily',
                  name: 'Límite diario de retirada en cajero',
                  value: setCurrencyFormat(this.dataCard.limits.atm.daily),
                  editable: false,
                  editing: false
                },
                {
                  key: 'physical-payments',
                  name: 'Pagos físicos',
                  value: "Sí",
                  editable: false,
                  editing: false
                },
                {
                  key: 'online-payments',
                  name: 'Pagos online',
                  value: "Sí",
                  editable: false,
                  editing: false
                },
                {
                  key: 'atm-withdrawal',
                  name: 'Retirada en cajeros',
                  value: "Sí",
                  editable: false,
                  editing: false
                },
              ]
            }
            this.isDataLoaded = true;
          })
        }

      });
  }


  activateCard() {
    this.showAlert = false;

    this.activate.activateCard(this.userId, this.accountId, this.cardId)
      .subscribe({
        next: (response) => {
          console.log("Tarjeta activada con éxito", response);
          this.routeTable.navigate(['/particulares/tarjetas']);
        },
        error: (error) => {
          console.error(error);
          this.showAlert = true;
          this.alertStatus = 'ko';
          this.alertMessage = "No se pudo activar la tarjeta.";
        }
      });
  }


  openToCancel() {
    const dialogRef = this.dialog.open(CardCancelComponent, {
      disableClose: true,
      data: {
        cardData: this.dataCard,
      },
    });
  }

  showPIN() {
    this.pinService.getPin(this.userId, this.accountId, this.cardId).subscribe(
      {
        next: (response) => {
          console.log(response);
          this.pin = response;
          window.open(this.pin, '_blank');
          this.showAlert = true;
          this.alertStatus = 'ok';
          this.alertMessage = "Su código PIN está disponible en la nueva pestaña que se ha abierto."
        },
        error: (error) => {
          console.log(error);
          this.showAlert = true;
          this.alertStatus = 'ko';
          this.alertMessage = "Error al recuperar el PIN"
        }
      }
    )
  }

  editAlias() {
    this.hiddenAlias = !this.hiddenAlias;
  }

  backToList(){
    this.detailEmit.emit(false)
  }

  onViewContract(): void {
    this.isPdfVisible = false;

    this.dmsService.getFilteredDocuments(this.dataCard.holderId, this.cardId).subscribe({
      next: (docs) => {
        this.filteredDocuments = docs;
        console.log('Documentos filtrados:', this.filteredDocuments);
        
        const documentToDownload = this.filteredDocuments[0];
        if (documentToDownload && documentToDownload.files && documentToDownload.files.length > 0) {
          const fileId = documentToDownload.files[0].id;
          if (fileId) {
            this.downloadDocument(this.dataCard.holderId, documentToDownload.id!, fileId);
          }
        }
      },
      error: (err) => {
        console.error('Error fetching filtered documents:', err);
      },
    });
  }

  downloadDocument(clientId: string, documentId: string, fileId: string): void {
    this.dmsService.downloadDocument(clientId, documentId, fileId).subscribe({
      next: (response) => {
        console.log(response)
        const blob = new Blob([response], { type: 'application/pdf' });

        this.filePreview = URL.createObjectURL(blob); 
        this.isPdfVisible = true; 
      },
      error: (err) => {
        console.error('Error downloading document:', err);
      },
    });
  }

  showAlertMessage(message: string, status: 'ok' | 'ko'): void {
    this.alertMessage = message;
    this.alertStatus = status;
    this.showAlert = true;
  
    setTimeout(() => {
      this.showAlert = false;
    }, 3000);
  }
}