<div class="modal-states">
    <p>Se actualizará el estado de la solicitud a</p>
    <h3>{{ stateFinancing(data.state) }}</h3>
    <div class="buttons">
        <button class="stroked-gold-button" (click)="onCancel()">
            Cancelar
        </button>
        <button class="gold-button" (click)="onConfirm()">
            Confirmar
        </button>
    </div>
</div>
