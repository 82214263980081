import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentFile } from '../../models/documents/documents';

export enum METHOD {
  DENARIUS = 'DENARIUS'
}

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {


  constructor(private http: HttpClient) { }

  public getDocument(clientId: string, documentId: string): Observable<any> {
    return this.http.get<any>(`${environment.dmsUrl}/${clientId}/documents/${documentId}`)
  }

  public downloadDocument(clientId: string, documentId: string, fileId: string): Observable<any> {
    let httpHeaders = new HttpHeaders().set('Accept', '*/*')
    return this.http.get<any>(`${environment.dmsUrl}/${clientId}/documents/${documentId}/file/${fileId}/download`, { headers: httpHeaders, responseType: 'blob' as 'json'})
  }
  public getAllDocuments(clientId: string): Observable<DocumentFile[]> {
    return this.http.get<DocumentFile[]>(`${environment.dmsUrl}/${clientId}/documents`);
  }


public getFilteredDocuments(userId: string, cardId: string): Observable<any[]> {
  return this.getAllDocuments(userId).pipe(
    map((documents: any[]) => 
      documents.filter(doc => doc.type === 'CTT' && doc.data?.cardId === cardId)
    )
  );
} 

  public verifyDocument(clientId: string, documentId: string, method: METHOD , body: any): Observable<any> {
    return this.http.post<any>(`${environment.dmsUrl}/${clientId}/documents/${documentId}/verification/${method}/verify`, body)
  }

  public rejectDocument(clientId: string, documentId: string, method: METHOD, body: any): Observable<any> {
    return this.http.post<any>(`${environment.dmsUrl}/${clientId}/documents/${documentId}/verification/${method}/reject`, body)
  }


}

