export function statusCard(status: string) {
    switch (status) {
        case 'PENDING_CONTRACT':
            return "Pendiente de firma";
        case 'PENDING_SHIPMENT':
            return "Pendiente de envío";
        case 'PRE_ACTIVATED':
            return "Preactivada";
        case 'ACTIVATED':
            return "Activada";
        case 'ANULLED':
            return "Anulada";
        case 'CANCELLED':
            return "Cancelada";
        case 'ERROR':
            return "Error";
        default:
            return "Estado desconocido";
    }
}

export function typeCard(type: string) {
    switch (type) {
        case 'DEBIT':
            return "Débito Física";
        default:
            return "Tipo desconocido";
    }
}

export function formatExpiration(dateStr: string): string {
    if (!dateStr || dateStr.length !== 4) return dateStr;
    const month = dateStr.slice(0,2);
    const year = dateStr.slice(2);
    if (parseInt(month) > 12) {
        return year + '/' + month;
    }
    return month + '/' + year;
}
