import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-document-modal',
  templateUrl: './send-document-modal.component.html',
  styleUrls: ['./send-document-modal.component.scss']
})
export class SendDocumentModalComponent {
  signers: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<SendDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.signers = data.signers.map((signer: any) => ({
      ...signer,
      selected: false
    }));
  }

  get isSendDisabled(): boolean {
    return !this.signers.some(signer => signer.selected);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  sendDocument(): void {
    const selectedSigners = this.signers.filter(signer => signer.selected);
    this.dialogRef.close(selectedSigners);
  }
}
