import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardConfiguration } from 'src/app/core/models/cards/card';

@Component({
  selector: 'app-card-configuration',
  templateUrl: './card-configuration.component.html',
  styleUrl: './card-configuration.component.scss'
})
export class CardConfigurationComponent {

  public cardConfigForm!: FormGroup;
  @Input() companyProfile: any;
  @Input() individualProfile: any;
  @Input() accounts: any;
  @Output() prev = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();
  @Output() cardsConfigEvent = new EventEmitter<CardConfiguration>();

  public holders: any;
  public multipleHolders: any;
  public multipleAccounts: any;

  constructor(private fb: FormBuilder) {
    this.cardConfigForm = this.fb.group({
      holder: ['', Validators.required],
      holderId: ['', Validators.required],
      alias: [''],
      associatedAccount: ['', Validators.required], 
      associatedAccountId: [''], 
    });
    
  }                               

  ngOnInit(){}

  ngOnChanges(): void {
    if (this.companyProfile) {
      this.processCompanyProfile();
    } else if (this.individualProfile) {
      this.setIndividualHolder();
    }
  }

  processCompanyProfile(): void {
    const combinedHolders = [
      this.companyProfile.representative,
      ...this.companyProfile.administrators,
      ...this.companyProfile.partners,
    ];
    this.holders = combinedHolders.filter(
      (holder, index, self) =>
        index === self.findIndex((t) => t.identifier === holder.identifier)
    );
  
    this.multipleHolders = this.holders.length > 1;
    this.multipleAccounts = this.accounts?.length > 1;
  
    if (this.holders.length === 1) {
      const holder = this.holders[0];
      this.cardConfigForm.patchValue({
        holder: `${holder.name} ${holder.surname1}`,
        holderId: holder.id
      });
      this.cardConfigForm.get('holder')?.disable();
    }
  
    if (this.accounts?.length === 1) {
      const account = this.accounts[0];
      this.cardConfigForm.patchValue({
        associatedAccount: account.iban,
        associatedAccountId: account.id,
      });
      this.cardConfigForm.get('associatedAccount')?.disable();
    }
  }

  setIndividualHolder(): void {
    this.cardConfigForm.patchValue({
      holder: `${this.individualProfile.name} ${this.individualProfile.surname1} ${this.individualProfile.surname2}`,
      holderId: this.individualProfile.id
    });
    this.cardConfigForm.get('holder')?.disable();
  }

  onAccountChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const selectedIban = target.value;
  
    if (selectedIban) {
      const account = this.accounts.find((acc: { iban: string; }) => acc.iban === selectedIban);
      if (account) {
        this.cardConfigForm.patchValue({
          associatedAccountId: account.id,
        });
      }
    }
  }

  onHolderChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const selectedHolderId = target.value;
  
    if (selectedHolderId) {
      const holder = this.holders.find((hold: { id: string; }) => hold.id === selectedHolderId);
      if (holder) {
        this.cardConfigForm.patchValue({
          holderId: holder.id,
        });
      }
    }
  }

  isFormValid(): boolean {
    return this.cardConfigForm.valid;
  }

  nextStep(): void {
    if (this.isFormValid()) {
      const cardConfig: CardConfiguration = {
        holder: this.cardConfigForm.get('holder')?.value,
        holderId:this.cardConfigForm.get('holderId')?.value,
        alias: this.cardConfigForm.get('alias')?.value || '',
        associatedAccount: this.cardConfigForm.get('associatedAccount')?.value,
        associatedAccountId: this.cardConfigForm.get('associatedAccountId')?.value,
      };
      this.cardsConfigEvent.emit(cardConfig);
      this.next.emit();
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.cardConfigForm.controls[controlName].hasError(errorName);
  }


}


