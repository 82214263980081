<div class="table_container">
    <div   class="tableMaterial" >
      <table  mat-table [dataSource]="dataSource"  *ngIf="!loader">
        <ng-container matColumnDef="accountingDate">
          <th mat-header-cell *matHeaderCellDef> Fecha contable </th>
          <td mat-cell *matCellDef="let element"> {{element.date |date: "dd/MM/yyyy"}} </td>
        </ng-container>
      
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Fecha valor </th>
          <td mat-cell *matCellDef="let element"> {{element.date |date: "dd/MM/yyyy"}} </td>
        </ng-container>
      
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Descripción </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>
      
        <ng-container matColumnDef="amount">
          <th class="text_right"  mat-header-cell *matHeaderCellDef> Importe </th>
          <td class="text_right" mat-cell *matCellDef="let element" [ngStyle]="{'color': element.amount > 0 ? 'green' : 'red'}"> {{element.amount |  currency: "€":"symbol":"1.2-2":"es"}} </td>
        </ng-container>
    
        <ng-container matColumnDef="balance">
            <th class="text_right" mat-header-cell *matHeaderCellDef> Saldo </th>
            <td class="text_right" mat-cell *matCellDef="let element"> {{element.balance |  currency: "€":"symbol":"1.2-2":"es"}} </td>
          </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;  let i = index" [ngClass]="i % 2 == 0? 'par' : 'impar'" ></tr> 
        <!-- [ngStyle]="i % 2 == 0 ? {'background-color' : 'white'} : {'background-color' : '#e0e0e052'}"  -->
      </table>
  ​
      <button  *ngIf="!loader && !hideNextButton" class="button-next" class="gold-button" (click)="nextPage()">Más movimientos</button>
      <mat-hint  *ngIf="!loader && movementsData.length == 0" class="mat-hint">No hay más movimientos</mat-hint>
  ​
      <div class="skeleton-container" *ngIf="loader" >
        <ngx-skeleton-loader  count="{{offset + (accumulatedData.length)}}" appearance="line" [theme]="{ height: '36px' }">
        </ngx-skeleton-loader>
      </div>
    </div>
     
  ​
  </div>