<div class="general-container">
    <div class="section_header">
        <div class="business_name">
            <p>{{businessData.name}}</p>
        </div>
    <app-navigation-buttons [subLinks]="subLinks" [selectedLink]="selectedLink" (selected)="receiveSelectedLink($event)"></app-navigation-buttons>
    </div>
    <div *ngIf="employees">
        <ng-container *ngComponentOutlet="selectedLink.component; injector: dynamicInjector"></ng-container> 
    </div>
   
</div>

