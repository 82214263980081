<div class="page_container" *ngIf="userAccounts && userAccounts.length >= 1">
  <div class="accounts_flex">
    <div class="selector_container">
      <app-selector-accounts
        [userAccounts]="userAccounts"
        (selectedAccount)="receiveSelectedAccount($event)"
      ></app-selector-accounts>
    </div>
    <div class="accounts_filter">
      <app-filter
        class="app-filter"
        (filtersEvent)="receiveFilters($event)"
      ></app-filter>
    </div>
  </div>
  <div class="accounts_month-filter">
    <!--         <app-month-filter (filtersEvent)="receiveFilters($event)"></app-month-filter> -->
    <div class="icons-list">
      <!-- <mat-icon>picture_as_pdf</mat-icon> -->
      <img
        class="svg_icon"
        (click)="exportToExcel()"
        src="../../../../../assets/img/svg/excel-full.svg"
        alt="excel_icon"
      />
      <button mat-button (click)="resetFilters()">Borrar filtros</button>
    </div>
  </div>
  <div class="table_container">
    <app-accounts-table
      [selectedAccountId]="selectedAccountId"
      [filterParams]="filterParams"
      (sendMovementsDownload)="receiveMovements($event)"
    ></app-accounts-table>
  </div>
</div>
<p class="no-p" *ngIf="userAccounts && userAccounts.length == 0">No hay cuentas</p>
