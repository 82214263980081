import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-summary',
  templateUrl: './card-summary.component.html',
  styleUrl: './card-summary.component.scss'
})
export class CardSummaryComponent {
  @Input() cardConfigData: any = {};
  @Input() shippingData: any = {};
  @Output() prev = new EventEmitter<void>();
  @Output() confirmRequest = new EventEmitter<void>();

  public dataCard: any;

  prevStep() {
    this.prev.emit();
  }

  confirm() {
    this.dataCard = {
       ...this.cardConfigData, 
       ...this.shippingData ,
       holderId: this.cardConfigData.holderId,
       alias: this.cardConfigData.alias,
      };
    this.confirmRequest.emit(this.dataCard); 
  }
}
