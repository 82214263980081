import { DatePipe, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Data, InfoTable } from 'src/app/core/models/info-table/info-table';
import { formatDateOnlyDay } from '../../../core/extensions/functions';
import { provideNativeDateAdapter } from '@angular/material/core';
registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.scss'],
  providers: [provideNativeDateAdapter()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTableComponent {
  public loader: boolean = true;
  @Input() infoTable!: InfoTable;
  @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() loaderHidden: any;
  
 public eighteenYearsAgo!: Date;
  
    formatDateOnlyDay = formatDateOnlyDay;

    public editedField: FormControl = new FormControl(null);


constructor( private datePipe: DatePipe){}

  ngOnInit() {
    this.loader = this.loaderHidden
  }



  showEdit(item: Data) {
    this.infoTable.data.forEach((elem) => (elem.editing = false));
    item.editing = true;
  
    const initialValue = item.value ? new Date(item.value) : null;
    this.editedField.setValue(initialValue);
  }
  
  

  hideEdit(item: Data) {
    item.editing = false;
  }
  editField(item: Data) {
    this.infoTable.data.forEach((elem) => {
      if (elem === item) {
        const rawValue = this.editedField.value;
  
        if (rawValue) {
          elem.value = this.datePipe.transform(rawValue, 'yyyy-MM-dd') || '';
        } else {
          elem.value = ''; // O algún valor predeterminado si rawValue es null
        }
  
        elem.editing = false;
        elem.saved = true;
  
        setTimeout(() => {
          elem.saved = false;
        }, 2000);
  
        this.editEvent.emit(elem);
      }
    });
  }
  
  
  
    filterFuture = (d: Date | null): boolean => {
      const today = new Date();
      const day = (d || new Date()).getDay();
      return d !== null && d > today;
    };


    filterPast = (d: Date | null): boolean => {
      if (d === null) {
        return false;
      }
    
      const today = new Date();
      this.eighteenYearsAgo = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
      );
    
      return d <= this.eighteenYearsAgo;
    };
    
}
