<div class="card-resumen">
    <h1>Solicitar tarjeta</h1>
    <h2>Resumen</h2>
    <h3>Comprueba tus datos y confirma la contratación de tu tarjeta</h3>
    <div class="data-content">
      <div class="double-content">
        <div class="double">
          <p>Titular de la tarjeta</p>
          <p class="content">{{cardConfigData.holder}}</p>
        </div>
          <div class="double">
          <p>Alias de la tarjeta</p>
          <p class="content">{{cardConfigData.alias || 'Sin alias'}}</p>
        </div>
      </div>
      <div class="double-content">
        <div class="double">
          <p>Cuenta Asociada</p>
          <p class="content">{{cardConfigData.associatedAccount}}</p>
        </div>
        <div class="double">
          <p>Dirección de envío</p>
          <p class="content">{{ shippingData.street }}</p>
          <p class="content">{{ shippingData.number }}</p>
          <p class="content">{{ shippingData.flat || 'No especificado' }}</p>
          <p class="content">{{ shippingData.country }}</p>
          <p class="content">{{ shippingData.city }}</p>
          <p class="content">{{ shippingData.zip }}</p>
        </div>
      </div>
    </div>
   
    <div class="button-group">
      <button class="stroked-gold-button" (click)="prevStep()">Volver</button>
      <button class="gold-button" (click)="confirm()">Confirmar Solicitud</button>
    </div>
  </div>