import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FinancingService } from 'src/app/core/services/financing/financing.service';
import { DeleteConfirmationModalComponent } from 'src/app/shared/components/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { DocumentDetailModalComponent } from 'src/app/shared/components/modals/document-detail-modal/document-detail-modal.component';
import { SendDocumentModalComponent } from 'src/app/shared/components/modals/send-document-modal/send-document-modal.component';
import { UploadDocumentModalComponent } from 'src/app/shared/components/modals/upload-document-modal/upload-document-modal.component';
import { ViewDocumentModalComponent } from 'src/app/shared/components/modals/view-document-modal/view-document-modal.component';

@Component({
  selector: 'app-financing-documents',
  templateUrl: './financing-documents.component.html',
  styleUrls: ['./financing-documents.component.scss']
})
export class FinancingDocumentsComponent {
  @Input() userData!: any;
  public offset: number = 10;
  public loader: boolean = false;
  isCompany!: string;
  signers: any[] = [];
  signersTableData: any[] = [];
  documents: any[] = [];
  tableData: any[] = [];

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private financingService: FinancingService 
  ) {
    this.isCompany = localStorage.getItem('type')!;
  }

  displayedColumnsDocuments = ['view', 'documentos', 'description', 'date', 'delete'];

  ngOnInit() {
    this.loadDocuments(); 
  }

  loadDocuments() {
    this.loader = true;
    this.financingService.getFinancingDocuments(this.userData.id).subscribe({
      next: (documents) => {
        this.documents = documents || [];
        this.updateTableData();
        this.loader = false;
      },
      error: (err) => {
        console.error('Error loading documents:', err);
        this.loader = false;
      }
    });
  }

  openUploadModal() {
    const dialogRef = this.dialog.open(UploadDocumentModalComponent, {
      data: { idFinancing: this.userData.id, type: 'documents' }
    });

    dialogRef.afterClosed().subscribe((newDocument) => {
      if (newDocument) {
        this.loadDocuments();
      }
    });
  }

  openSendDocumentModal(document: any) {
    const dialogRef = this.dialog.open(SendDocumentModalComponent, {
      data: { signers: this.signers }
    });

    dialogRef.afterClosed().subscribe((selectedSigners) => {
      if (selectedSigners) {
        document.state = 'Enviado';
        document.signers = selectedSigners;
      }
    });
  }

  openDocumentDetailModal(document: any) {
    if (document.state !== 'Enviado') {
      console.warn('El documento debe estar en estado "Enviado" para ver los detalles.');
      return;
    }

    const dialogRef = this.dialog.open(DocumentDetailModalComponent, {
      data: {
        date: document.date,
        signers: document.signers
      }
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  openViewDocumentModal(document: any) {
    this.dialog.open(ViewDocumentModalComponent, {
      data: {
        idFinancing: this.userData.id,
        idDocument: document.id,
        format: document.media_type,
        name: document.documentos
      }
    });
  }
  

  confirmDeleteDocument(documentId: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationModalComponent, {
      data: { message: `¿Estás seguro de que deseas eliminar este documento?` }
    });
  
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.loader = true;
        this.financingService.deleteDocument(this.userData.id, documentId).subscribe({
          next: () => {
            this.documents = this.documents.filter(doc => doc.id !== documentId);
            this.updateTableData();
            this.cdr.detectChanges();
            this.loader = false;
          },
          error: (err) => {
            console.error('Error al eliminar el documento:', err);
            this.loader = false; 
          }
        });
      }
    });
  }

  updateTableData() {
    this.tableData = this.documents.map((document) => ({
      id: document.id,
      financing_id: document.financing_id,
      previewUrl: document.previewUrl || `https://your-server.com/documents/${document.id}/preview`,
      documentos: document.name || 'Documento',
      comment: document.description || 'Pendiente',
      date: document.date || new Date(),
      media_type: document.media_type || 'application/pdf',
      signers: document.signers || [],
      delete: 'delete',
    }));
  }
}
