<div class="form-upload modal-content">
  <h2>Selecciona los firmantes</h2>
  
  <div class="form-group" *ngFor="let signer of signers">
    <label>
      <mat-checkbox [(ngModel)]="signer.selected">{{ signer.fullName }}</mat-checkbox>
    </label>
  </div>
  
  <div class="buttons modal-actions">
    <button mat-button class="gold-button" (click)="closeModal()">Cancelar</button>
    <button mat-button class="gold-button" (click)="sendDocument()" [disabled]="isSendDisabled">Enviar</button>
  </div>
</div>
