import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import { FinancingService } from 'src/app/core/services/financing/financing.service';

@Component({
  selector: 'app-upload-signer-modal',
  templateUrl: './upload-signer-modal.component.html',
  styleUrls: ['./upload-signer-modal.component.scss']
})
export class UploadSignerModalComponent implements OnInit {
  signerForm: FormGroup;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UploadSignerModalComponent>,
    private financingService: FinancingService, 
    private snackBar: MatSnackBar, 
    @Inject(MAT_DIALOG_DATA) public data: any 
  ) {
    this.signerForm = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      identifier: ['']
    });
  }

  ngOnInit() {
    if (this.data) {
      this.signerForm.patchValue({
        name: this.data.name || '',
        surname: this.data.surname || '',
        email: this.data.email || '',
        phone: this.data.phone || '',
        identifier: this.data.identifier || ''
      });
    }
  }
  

  onSubmit() {
    if (this.signerForm.valid) {
      this.loading = true;
      const formValue = this.signerForm.value;
      const signerData = {
        name: formValue.name,
        surname: formValue.surname,
        email: formValue.email,
        phone: formValue.phone,
        identifier: formValue.identifier
      };
  
      const request$ = this.data.isEdit
        ? this.financingService.editSignerDocument(this.data.idFinancing, this.data.idSigner, signerData)
        : this.financingService.addSignerDocument(this.data.idFinancing, signerData);
  
      request$.subscribe({
        next: (response) => {
          this.loading = false;
          const action = this.data.isEdit ? 'editado' : 'creado';
          this.snackBar.open(`Firmante ${action} con éxito`, 'Cerrar', { duration: 3000 });
          this.dialogRef.close(true); 
        },
        error: (error) => {
          this.loading = false;
          const action = this.data.isEdit ? 'editar' : 'crear';
          console.error(`Error al ${action} el firmante:`, error);
          this.snackBar.open(`Error al ${action} el firmante`, 'Cerrar', { duration: 3000 });
        }
      });
    }
  }
  
  

  onCancel() {
    this.dialogRef.close();
  }
}
