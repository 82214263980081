<div class="section_header">
  <p class="section_title">Particulares</p>
  <app-search-bar [placeholder]="placeholder" (searchEvent)="setQuerySearch($event)"></app-search-bar>
</div>

<div class="filters container">
 <app-users-filter (filtersEvent)="receiveFilters($event)"></app-users-filter>
</div>
<div class="buttons-wrap">
  <div class="buttons_container">
    <button class="gold-button" routerLink="crear-usuario">+ Crear usuario</button>
  </div>
  <div class="status-button">
    <mat-button-toggle-group name="state" aria-label="state" [(ngModel)]="stateValue" (change)="activeFilter()" hideSingleSelectionIndicator>
      <mat-button-toggle value="active">Activos</mat-button-toggle>
      <mat-button-toggle value="inactive">Inactivos</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div class="tableMaterial" *ngIf="!loader">
    <table mat-table [dataSource]="dataSource" class="table-container">
        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef>Ver</th>
            <td mat-cell *matCellDef="let element" (click)="getUser(element.id, element.fullName)"><div class="icon-link"><mat-icon style="cursor:pointer">person</mat-icon></div></td>
        </ng-container>
            <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef> Nombre completo </th>
                <td mat-cell *matCellDef="let element"><p>{{element.fullName}}</p>  </td>
            </ng-container>
        <ng-container matColumnDef="documentType">
            <th mat-header-cell *matHeaderCellDef> Tipo de documento</th>
            <td mat-cell *matCellDef="let element"><p class="centered">{{element.documentType}}</p>  </td>
        </ng-container>
        <ng-container matColumnDef="identifier">
            <th mat-header-cell *matHeaderCellDef>Número</th>
            <td mat-cell *matCellDef="let element"><p class="gold-p">{{element.identifier}}</p>  </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> Fecha creación </th>
            <td mat-cell *matCellDef="let element"><p>{{element.createdAt | date: "dd/MM/yyyy"}}</p>  </td>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef> Última actualización</th>
            <td mat-cell *matCellDef="let element"> <p>{{element.updatedAt | date: "dd/MM/yyyy"}}</p>  </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element" >
                <p [ngClass]="{
                    'user_statusOk': element.state == 'CONTRACT_SIGNED',
                    'user_statusKo': !(element.state == 'CONTRACT_SIGNED')}">
                    {{ (element.state == 'CONTRACT_SIGNED') ? 'Activo' : 'Inactivo' }}</p>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;  let i = index"></tr>
    </table>
    <p *ngIf="dataSource.length == 0">No se han encontrado clientes.</p>
    <div class="pagination-info" *ngIf="responseUser && !loader">
     <p>Usuarios {{ stateValue === 'active' ? 'activos' : 'inactivos' }}: {{ responseUser.total }} {{ stateValue === 'active' ? responseUser.activeTotal : responseUser.inactiveTotal }}</p> 
    </div>
    <mat-paginator
    (page)="onPageChange($event)"
    [pageIndex]="currentPage"
    [hidePageSize]="true"
    [length]="responseUser.total"
    [pageSize]="offset"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
    </mat-paginator>

</div>

<div class="skeleton-container" *ngIf="loader" >
    <ngx-skeleton-loader  count="{{offset-1}}" appearance="line" [theme]="{ height: '36px' }">
    </ngx-skeleton-loader>
</div>
