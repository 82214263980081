import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { stateFinancing } from 'src/app/core/extensions/stateFinancing.extension';

@Component({
  selector: 'app-modal-states',
  standalone: true,
  imports: [],
  templateUrl: './modal-states.component.html',
  styleUrl: './modal-states.component.scss',
})
export class ModalStatesComponent {
  action: Function | undefined;
  stateFinancing = stateFinancing;
  constructor(
    public dialogRef: MatDialogRef<ModalStatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.action = data.function;
  }

  onConfirm(): void {
    if(this.action) {
      this.action()
    }
    this.dialogRef.close('ok')
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
