import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
import { FilterComponent } from './components/filter/filter.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SelectSegmentComponent } from './components/select-segment/select-segment.component';
import { NgClass } from '@angular/common';
import { AlertsComponent } from './components/alerts/alerts.component';
import { SegmentFormatPipe } from './pipes/segmentId.pipe';
import { CountryPipe } from './pipes/country.pipe';
import { ClientHeaderComponent } from './layout/client-header/client-header.component';
import { HostComponent } from './layout/host/host.component';
import { HostDirective } from '../core/directives/component.host.directive';
import { HeaderLinksService } from '../core/services/headerLinks/link.service';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { UserKycComponent } from './components/user-kyc/user-kyc.component';
import { FailedOperationsComponent } from './components/failed-operations/failed-operations.component';
import { InfoTableComponent } from './components/info-table/info-table.component';
import { AccountDetailComponent } from './components/user-accounts/account-detail/account-detail.component';
import { SelectorAccountsComponent } from './components/user-accounts/selector-accounts/selector-accounts.component';
import { AccountsTableComponent } from './components/user-accounts/accounts-table/accounts-table.component';
import { UserCardsComponent } from './components/user-cards/user-cards.component';
import { UserAccountsComponent } from './components/user-accounts/user-accounts.component';
import { UserDocumentsComponent } from './components/user-documents/user-documents.component';
import { HeaderDocsComponent } from './components/user-documents/header-docs/header-docs.component';
import { TableDocsComponent } from './components/user-documents/table-docs/table-docs.component';
import { ViewerDocsComponent } from './components/user-documents/viewer-docs/viewer-docs.component';
import { NavigationButtonsComponent } from './layout/navigation-buttons/navigation-buttons.component';
import { CardsTableComponent } from './components/user-cards/cards-table/cards-table.component';
import { DocumentHandlerComponent } from './components/user-documents/document-handler/document-handler.component';
import { Card } from '../core/models/plans/plans';
import { CompanyDataComponent } from './components/onboarding-forms/company-data/company-data.component';
import { PersonalDataComponent } from './components/onboarding-forms/personal-data/personal-data.component';
import { PersonalAdressComponent } from './components/onboarding-forms/personal-adress/personal-adress.component';
import { SelectPlanComponent } from './components/onboarding-forms/select-plan/select-plan.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { InputEmailComponent } from './components/input-email/input-email.component';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UploadDocumentModalComponent } from './components/modals/upload-document-modal/upload-document-modal.component';
import { UploadSignerModalComponent } from './components/modals/upload-signer-modal/upload-signer-modal.component';
import { SendDocumentModalComponent } from './components/modals/send-document-modal/send-document-modal.component';
import { DocumentDetailModalComponent } from './components/modals/document-detail-modal/document-detail-modal.component';
import { DeleteConfirmationModalComponent } from './components/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { ViewDocumentModalComponent } from './components/modals/view-document-modal/view-document-modal.component';
import { CardsDetailComponent } from './components/user-cards/cards-detail/cards-detail.component';
import { CardsCreateComponent } from './components/user-cards/cards-create/cards-create.component';
import { CardConfigurationComponent } from './components/user-cards/cards-create/card-configuration/card-configuration.component';
import { CardAddressComponent } from './components/user-cards/cards-create/card-address/card-address.component';
import { CardSummaryComponent } from './components/user-cards/cards-create/card-summary/card-summary.component';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoginFormComponent,
    SideNavComponent,
    FilterComponent,
    SelectSegmentComponent,
    AlertsComponent, 
    SegmentFormatPipe,
    CountryPipe,
    ClientHeaderComponent,
    HostComponent,
    HostDirective, 
    SearchBarComponent,
    UserKycComponent,
    FailedOperationsComponent,
    InfoTableComponent,
    UserAccountsComponent,
    AccountsTableComponent,
    AccountDetailComponent,
    SelectorAccountsComponent,
    UserCardsComponent,
    UserDocumentsComponent,
    HeaderDocsComponent, 
    TableDocsComponent,
    ViewerDocsComponent,
    CardsTableComponent,
    NavigationButtonsComponent,
    DocumentHandlerComponent,
    CompanyDataComponent,
    PersonalDataComponent,
    PersonalAdressComponent,
    SelectPlanComponent,
    UploadFileComponent,
    ConfirmModalComponent, 
    InputEmailComponent,
    SpinnerComponent,
    UploadDocumentModalComponent,
    UploadSignerModalComponent,
    SendDocumentModalComponent,
    DocumentDetailModalComponent,
    DeleteConfirmationModalComponent,
    ViewDocumentModalComponent,
    CardsDetailComponent,
    CardsCreateComponent,
    CardConfigurationComponent,
    CardAddressComponent,
    CardSummaryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    AngularEditorModule,
    PdfViewerModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HeaderComponent,
    FooterComponent,
    LoginFormComponent,
    SideNavComponent,
    HttpClientModule,
    FilterComponent,
    NgxSkeletonLoaderModule,
    SelectSegmentComponent,
    AlertsComponent,
    AngularEditorModule,
    SegmentFormatPipe,
    CountryPipe,
    ClientHeaderComponent,
    HostComponent,
    HostDirective,
    SearchBarComponent,
    UserKycComponent,
    FailedOperationsComponent,
    InfoTableComponent,
    UserAccountsComponent,
    AccountDetailComponent,
    SelectorAccountsComponent,
    UserDocumentsComponent, 
    TableDocsComponent,
    ViewerDocsComponent,
    CardsTableComponent,
    NavigationButtonsComponent, 
    DocumentHandlerComponent,
    CompanyDataComponent,
    PersonalDataComponent,
    PersonalAdressComponent,
    SelectPlanComponent,
    UploadFileComponent,
    AngularEditorModule,
    ConfirmModalComponent,
    InputEmailComponent,
    SpinnerComponent,
    UploadDocumentModalComponent,
    UploadSignerModalComponent,
    SendDocumentModalComponent,
    DocumentDetailModalComponent,
    DeleteConfirmationModalComponent,
    ViewDocumentModalComponent,
    CardsDetailComponent,
    CardsCreateComponent,
    CardConfigurationComponent,
    CardAddressComponent,
    CardSummaryComponent
  ],
  providers: [
    HeaderLinksService,
    DatePipe,
    CurrencyPipe,
    {provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
  ],

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class SharedModule { }
