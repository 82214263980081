
<div class="section_header">
    <p>Permisos de usuario</p>
</div>
<form class="userPermits_form">
    <div class="toggle">
      <p>Transferencias, pagos y retiradas de dinero</p>  <mat-slide-toggle  color="primary"color="primary" [formControl]="cashOut"></mat-slide-toggle>
    </div>
    <div class="toggle">
      <p>Facturación</p>  <mat-slide-toggle  color="primary"color="primary" [formControl]="billing"></mat-slide-toggle>
    </div>
    <div class="toggle">
      <p>Tarjetas</p>  <mat-slide-toggle  color="primary"color="primary" [formControl]="cards"></mat-slide-toggle>
    </div>
    <button class="gold-button" type="submit">Guardar cambios</button>
</form>
