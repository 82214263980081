<div class="company-data-content">
  <h1>Solicitar tarjeta</h1>
  <h2>Dirección de envío</h2>
  <form class="company-data-form" [formGroup]="cardShippingForm">
    <div class="first">
      <div class="form-input">
        <div class="direccion-opciones">
          <div
            class="direccion-caja"
            [class.active]="selectedAddress === 'habitual'"
            (click)="selectAddress('habitual')"
          >
            <h4>A tu domicilio habitual</h4>
            <p>
              {{ regularAddress.street }}<br />
              {{ regularAddress.city }}<br />
              {{ regularAddress.country }}<br />
              {{ regularAddress.zip }}
            </p>
          </div>
          <div
            class="direccion-caja"
            [class.active]="selectedAddress === 'otra'"
            (click)="selectAddress('otra')"
          >
            <h4>Enviar a otra dirección</h4>
            <p>
              Indica una nueva<br />
              dirección para recibir la<br />
              tarjeta. Solo se usará<br />
              para este envío.
            </p>
          </div>
        </div>
      </div>

      <div class="form-input">
        <div class="long_input">
          <h3>Calle</h3>
          <input formControlName="street" />
        </div>
      </div>
      <div class="double">
        <div class="form-input">
          <div class="long_input">
            <h3>Número</h3>
            <input type="text" formControlName="number"/>
          </div>
        </div>

        <div class="form-input">
          <div class="long_input">
            <h3>Escalera / Letra / Piso (Opcional)</h3>
            <input
              formControlName="flat"
            />
          </div>
        </div>
      </div>

      <div class="form-input">
        <div class="long_input">
          <h3>País</h3>
          <select formControlName="country" placeholder="País">
            <option value="" disabled selected>Selecciona tu país</option>
            <option *ngFor="let item of country" [value]="item.value">
              {{ item.viewValue }}
            </option>
          </select>
        </div>
      </div>
      <div class="double">
        <div class="form-input">
          <div class="long_input">
            <h3>Población</h3>
            <input formControlName="city" />
          </div>
        </div>
  
        <div class="form-input">
          <div appearance="outline" class="long_input">
            <h3>Código Postal</h3>
            <input formControlName="zip" />
          </div>
        </div>
      </div>
      </div>
    <div class="button-next">
      <button class="stroked-gold-button" (click)="prevStep()">Cancelar</button>
      <button
        class="gold-button"
        [disabled]="!cardShippingForm.valid"
        (click)="nextStep()"
      >
        Siguiente
      </button>
    </div>
  </form>
</div>
