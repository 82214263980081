<div class="form">
  <h2>Nuevo / Editar firmante</h2>
  <form [formGroup]="signerForm" (ngSubmit)="onSubmit()">
    <div class="form-content">
      <div class="content">
        <div class="inputs">
          <label>Nombre</label>
          <input formControlName="name" required />
        </div>
        <div class="inputs">
          <label>DNI</label>
          <input formControlName="identifier" />
        </div>
        <div class="inputs">
          <label>Teléfono</label>
          <input formControlName="phone" />
        </div>
      </div>
      <div class="content">
        <div class="inputs">
          <label>Apellidos</label>
          <input formControlName="surname" required />
        </div>
        <div class="inputs">
          <label>Email</label>
          <input formControlName="email" required />
        </div>
      </div>
    </div>
      <div class="actions">
        <button type="button" class="stroked-gold-button" (click)="onCancel()">Cancelar</button>
        <button type="submit" [disabled]="!signerForm.valid" class="gold-button">Agregar</button>
      </div>
    
  </form>
</div>