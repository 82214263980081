<!-- <app-filter></app-filter> -->
<div class="section_header">
    <p class="section_title">Empresas</p>
    <app-search-bar [placeholder]="placeholder" (searchEvent)="setQuerySearch($event)"></app-search-bar>
</div>
<app-filters-business (filtersEvent)="receiveFilters($event)"></app-filters-business>
<div class="buttons-wrap">
    <div class="create-button">
      <button class="gold-button" routerLink="crear">+ Crear
        empresa</button>
    </div>

  <div class="status-button">
    <mat-button-toggle-group name="state" aria-label="state" [(ngModel)]="stateValue" (change)="activeFilter()" hideSingleSelectionIndicator>
      <mat-button-toggle value="active">Activos</mat-button-toggle>
      <mat-button-toggle value="inactive">Inactivos</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<app-table-business *ngIf="!loader" [dataSource]="dataSource"
    (getClientEvent)="onGetClientEvent($event)">
</app-table-business>

<div class="pagination-info" *ngIf="listData && !loader">
  <p>Usuarios {{ stateValue === 'active' ? 'activos' : 'inactivos' }}: {{ listData.total }} </p>
</div>

<mat-paginator *ngIf="listData && !loader" (page)="onPageChange($event)" [hidePageSize]="true"  [length]="listData.total" [pageIndex]="currentPage -1"
[pageSize]="offset" showFirstLastButtons aria-label="Select page of periodic elements">
</mat-paginator>


<div class="skeleton-container" *ngIf="loader">
    <ngx-skeleton-loader count="{{offset-1}}" appearance="line" [theme]="{ height: '36px' }">
    </ngx-skeleton-loader>
</div>
