<mat-dialog-actions class="actions-viewer" align="end">
  <mat-icon role="button" mat-dialog-close>close</mat-icon>
</mat-dialog-actions>
<mat-dialog-content class="dialog">
  <!--  <ng-container>
        <p>Este documento está en estado: <strong>{{data.status}}</strong></p>
        <p>¿Desea cambiar a estado: <strong>{{data.statusSelected.action}}</strong>?</p>
      <div class="buttons-container">
        <button mat-flat-button (click)="showConfirm()">Sí</button>
        <button mat-flat-button mat-dialog-close>No</button>
      </div>
    </ng-container>
    <ng-container *ngIf="showInputConfirm" class="confirm-content">
      <div class="input-confirm">
        <p>Para confirmar, escriba <strong>&quot;{{data.statusSelected.action}}&quot;</strong> en la caja de abajo </p>
        <input type="text" required autocomplete="off" (input)="confirm($event.target, data.statusSelected)">
      </div>
        <button mat-flat-button color="primary" (click)="sendNewStatus(data.statusSelected)">{{data.statusSelected}}</button>
    </ng-container> -->
  <p>Para <strong>{{data.statusSelected.action}}</strong> este documento, se requiere de la siguiente información:</p>
  <form [formGroup]="actionForm" class="dialog-content">
    <mat-form-field appearance="outline" color="primary" *ngIf="action == 'Validar'" class="long_input">
      <mat-label>Fecha de expedición</mat-label>
      <input formControlName="expedition" matInput [matDatepicker]="expedition" />
      <mat-hint>dd/MM/AAAA</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="expedition"></mat-datepicker-toggle>
      <mat-datepicker #expedition></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" color="primary" *ngIf="action == 'Validar'" class="long_input">
      <mat-label>Fecha de expiración (si procede)</mat-label>
      <input formControlName="expiration" matInput [matDatepicker]="expiration" />
      <mat-hint>dd/MM/AAAA</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="expiration"></mat-datepicker-toggle>
      <mat-datepicker #expiration></mat-datepicker>
    </mat-form-field>

    <mat-form-field floatLabel="always" *ngIf="action == 'Rechazar'" class="extra-long_input">
      <mat-label>Motivo de rechazo</mat-label>
      <mat-select name="reason" formControlName="reason" (selectionChange)="onReasonChange()">
        <mat-option *ngFor="let item of denyList" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="showInput" class="extra-long_input">
      <mat-label>Indique el motivo del rechazo</mat-label>
      <input matInput formControlName="other" #username>
      <mat-error *ngIf="!actionForm.get('other')?.valid && (actionForm.get('other')?.dirty)">Introduce un
        motivo</mat-error>
    </mat-form-field>
    <button mat-flat-button color="primary" [disabled]="!actionForm.valid || spinner" class="gold-button-green" 
      (click)="onSubmit()">Confirmar</button>
    <div *ngIf="spinner" class="spinner"></div>
  </form>
</mat-dialog-content>