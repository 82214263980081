export function getUserRole(role: UserRole): string {
    if(role === 'BUSINESS'){
        return 'empresas'
    } else if (role === 'INDIVIDUAL') {
        return 'particulares'
    } else {
        return ''
    }
}

export enum UserRole {
    INDIVIDUAL = 'INDIVIDUAL',
    BUSINESS = 'BUSINESS',
}

export function getFinancingUserType(id: string): boolean {
    return /^[a-zA-Z]/.test(id)
}
