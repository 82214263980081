import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BusinessService } from 'src/app/core/services/user/business.service';
import { kycConvert } from '../../../../core/extensions/kyc.extension';

@Component({
  selector: 'app-table-business',
  templateUrl: './table-business.component.html',
  styleUrls: ['./table-business.component.scss'],
})

export class TableBusinessComponent {
  @Input() dataSource: any;
  @Output() getClientEvent: EventEmitter<{userId: string, name:string}> = new EventEmitter<{userId: string, name:string}>();

  kycConvert = kycConvert

  public representativeName!: string;
  public displayedColumns: string[] = [
    'link',
    'name',
    'brand',
    'vatNumber',
    'country',
    'cnae',
    'representative',
    'incorporationDate',
    'active',
    'kyc'
  ];

  public idsCompanies: any;

  constructor(private businessCompany: BusinessService) {}

  ngOnInit() {

  }

  getYear(dateString: string): number {
    const date = new Date(dateString);
    return date.getFullYear();
  }

  getClient(userId: string, name:string) {
    localStorage.setItem('userId', userId);
    localStorage.setItem('userName', name);
    localStorage.setItem('type', 'Empresa');
    this.getClientEvent.emit({userId, name});
  }

  tableData(): boolean {
    return this.dataSource && this.dataSource.length > 0;
  }
}
