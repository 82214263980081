<div class="dialog-header">
  <h2>Estados</h2>
  <button mat-icon-button (click)="closeDialog()" mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content>
 
  <ul>
    <li *ngFor="let status of data.statusList; let i = index" >
    <div class="circle">{{ i + 1 }}</div> <span class="explanationStatus-span">{{ status.explanation }}</span>
  </li>
</ul>
</mat-dialog-content>  