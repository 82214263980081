import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Cards } from '../../models/cards/card';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(private http: HttpClient) {}

  activateCard(userId: string, accountId: string, cardId: string, body?:any ): Observable<any> {
    return this.http.post<any>(`${environment.coreUrl}/${userId}/account/${accountId}/cards/${cardId}/activate`, body );
  }

  deleteCard(userId: string, accountId: string, cardId: string): Observable<any> {
    return this.http.delete<any>(`${environment.coreUrl}/${userId}/account/${accountId}/cards/${cardId}`);
  }

  getPin(userId: string, accountId: string, cardId: string): Observable<any> {
    return this.http.get<any>(`${environment.coreUrl}/${userId}/account/${accountId}/cards/${cardId}/pin`);
  }
  
  public getAllCards(userId: string): Observable<Cards[]> {
    return this.http.get<Cards[]>(`${environment.coreUrl}/${userId}/cards`)
  }

  public getCard(userId: string, accountId: string, cardId: string): Observable<any> {
    return this.http.get<any>(`${environment.coreUrl}/${userId}/account/${accountId}/cards/${cardId}`)
  }

  public createCard(userId: string, accountId: string, cardData: any): Observable<any> {
    return this.http.post<any>(
      `${environment.coreUrl}/${userId}/account/${accountId}/cards`,
      cardData
    );
  }
}
