<div class="info_container">
    <div class="tableMaterial">
        <table mat-table [dataSource]="dataSource" class="table-container" *ngIf="dataSource.data.length < 0">
            <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef> Nombre completo </th>
                <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
            </ng-container>
            <ng-container matColumnDef="operationType">
                <th mat-header-cell *matHeaderCellDef> Tipo de operación </th>
                <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
            </ng-container>
            <ng-container matColumnDef="identifier">
                <th mat-header-cell *matHeaderCellDef> nº de documento</th>
                <td mat-cell *matCellDef="let element"> {{element.identifier}} </td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef> Fecha creación </th>
                <td mat-cell *matCellDef="let element"> {{element.createdAt | date: "dd/MM/yyyy"}} </td>
            </ng-container>
            <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef> Última actualización</th>
                <td mat-cell *matCellDef="let element"> {{element.updatedAt | date: "dd/MM/yyyy"}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;  let i = index"></tr>
        </table> 
        <div class="empty-message">
            <p *ngIf="dataSource.data.length > 0">No hay operaciones fallidas</p>
        </div>
        
    <!--  <mat-paginator
        (page)="onPageChange($event)"
        [length]="responseUser.total" 
        [pageIndex]="currentPage -1" 
        [pageSize]="offset"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
        </mat-paginator> -->
 </div>
</div>
