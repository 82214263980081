<div class="all-searchs">
  <div class="desplegable">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Filtrar clientes
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon>filter_alt</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <form [formGroup]="filterForm">
          <div class="form_container">
            <div>
               <mat-form-field appearance="outline" class="input-medium">
              <mat-label>Sexo</mat-label>
              <mat-select formControlName="gender">
                <mat-option value="F">Femenino</mat-option>
                <mat-option value="M">Mascuino</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="input-medium">
              <mat-label>Documento</mat-label>
              <mat-select formControlName="documentType">
                <mat-option value="DNI">DNI/NIE</mat-option>
                <mat-option value="PAS">Pasaporte</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
           

            <div class="range_container">
              <span class="input-hint">Fecha de nacimiento:</span>
              <div class="inputs">
                <mat-form-field appearance="outline" color="primary" class="input-medium">
                  <mat-label>Desde</mat-label>
                  <input formControlName="bornFrom" matInput [matDatepicker]="bornFrom" />
                  <mat-datepicker-toggle matIconSuffix [for]="bornFrom"></mat-datepicker-toggle>
                  <mat-datepicker #bornFrom></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" color="primary" class="input-medium">
                  <mat-label>Hasta</mat-label>
                  <input formControlName="bornTo" matInput [matDatepicker]="bornTo" />
                  <mat-datepicker-toggle matIconSuffix [for]="bornTo"></mat-datepicker-toggle>
                  <mat-datepicker #bornTo></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="range_container">
              <span class="input-hint">Fecha de creación:</span>
              <div class="inputs">
                <mat-form-field appearance="outline" color="primary" class="input-medium">
                  <mat-label>Desde</mat-label>
                  <input formControlName="createdFrom" matInput [matDatepicker]="createdFrom" [max]="maxDate"/>
                  <mat-datepicker-toggle matIconSuffix [for]="createdFrom"></mat-datepicker-toggle>
                  <mat-datepicker #createdFrom></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" color="primary" class="input-medium">
                  <mat-label>Hasta</mat-label>
                  <input formControlName="createdTo" matInput [matDatepicker]="createdTo" [max]="maxDate"/>
                  <mat-datepicker-toggle matIconSuffix [for]="createdTo"></mat-datepicker-toggle>
                  <mat-datepicker #createdTo></mat-datepicker>
                </mat-form-field>

              </div>
            </div>
          </div>
        </form>

        <button class="gold-button" (click)="resetAll()">Borrar filtros</button>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>