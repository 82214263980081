<div class="cancel-container">
  <div class="title-container">
      <h1>Cancelar Tarjeta</h1>
      <p>Una vez cancelada no se podrá reactivar esta tarjeta.</p>
  </div>
  <div class="info-content">
      <div class="icon-card">
          <mat-icon>credit_card</mat-icon>
      </div>

      <div class="info-card">
          <h3 class="bold">{{dataCard.cardData.name}}</h3>
          <p>{{ dataCard.cardData.maskedPAN }}{{ dataCard.cardData.alias ? ' / ' + dataCard.cardData.alias : '' }}</p>
      </div>
  </div>
  <div *ngIf="showAlert">
      <app-alerts [alertMessage]="alertMessage" [alertStatus]="alertStatus"></app-alerts>
    </div>
  <div class="btn-actions">
      <button mat-flat-button class="stroked-gold-button" (click)="closeModal()">Cancelar</button>
      <button mat-flat-button class="gold-button" (click)="confirmDelete()">Confirmar</button>
  </div>
</div>
