import { UserData } from './../../../../core/models/user/userData';
import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { getProvinceByCp } from 'src/app/core/extensions/getProvince';
import { kycConvert } from 'src/app/core/extensions/kyc.extension';
import { ZipCodeModel } from 'src/app/core/models/selects/selects';
import { ZipcodelistMockService } from 'src/app/core/services/selects/zipcodelist.mock-service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent {
  public userId!: string;
  public userInfo!: any;
  public kycInfo!: any;
  public userAvatar!: string;
  public editedField: FormControl = new FormControl();
  public loader!: boolean;
  public addressInfo!: any;
  public zipCode!: string;
  public provinceData!: ZipCodeModel;
  public userStatus!: string;
  public showKyc: boolean = false;
  public showFailedOp: boolean = false;
  public state!: string;
  kycConvert = kycConvert;

  @Input() userData!: UserData
  constructor(
    private userService: UserService,
    private datePipe: DatePipe,
    private provinceList: ZipcodelistMockService
  ) {}

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId')!;
    this.userStatus = 'Activo';
    this.getUserData();
  }
  

  getDniState(state?:string){
    if(state == 'DOCUMENTS_VERIFIED' || state == 'CONTRACT_SIGNED' ) {
      return 'Sí'
    } else return 'No'
  }


  getUserData() {
    this.loader = true;
    this.userService.getUser(this.userId).subscribe((res) => {
      this.userData = res;
      this.state = res.state;
      this.zipCode = this.userData.addresses[0].zip;
      this.getProvinceList();

      if (res.avatar) {
        this.userAvatar = `data:image/jpeg;base64,${res.avatar}`;
      } else {
        this.userAvatar = '../../../assets/img/avatar-icon.png';
      }

      this.userStatus = res.state;

      this.addressInfo =
        {
          icon:"badge",
          title:"Información Personal",
          data: [
            {
              key: 'name',
              name: 'Nombre',
              value: this.userData.fullName,
              editable: true,
              editing: false 
            },
            {
              key: 'phone',
              name: 'Teléfono',
              value: this.userData.phone,
              editable: true,
              editing: false
            },
            {
              key: 'address',
              name: 'Dirección',
              value: `${this.userData.addresses[0].via} ${this.userData.addresses[0].street} ${this.userData.addresses[0].number} ${this.userData.addresses[0].flat} ${this.userData.addresses[0].door} ${this.userData.addresses[0].other}` || "No se ha definido",
              editable: true,
              editing: false        
            },
            {
              key: 'location',
              name: 'Localidad',
              value: this.userData.addresses[0].city || "No se ha definido",
              editable: true,
              editing: false        
            },
            {
              key: 'province',
              name: 'Provincia',
              value: this.provinceData.province || "No se ha definido",
              editable: true,
              editing: false        
            },
            {
              key: 'zipCode',
              name: 'Código postal',
              value: this.userData.addresses[0].zip || "No se ha definido",
              editable: true,
              editing: false        
            },
          ]
        }
      
      this.userInfo = {
        icon:"badge",
        title: "Dirección de envío",
        data: [
          {
            key: 'name',
            name: 'Nombre',
            value: this.userData.name
          },
           {
             name: 'Primer apellido',
             value: this.userData.surname1
           },
           {
             name: 'Segundo apellido',
             value: this.userData.surname2
           },
          {
            key: 'birthdate',
            name: 'Fecha de nacimiento',
            value: this.datePipe.transform(this.userData.birthdate, 'dd-MM-YYYY'),
          },
          {
            key: 'nationality',
            name: 'Nacionalidad',
            value: this.userData.nationality === 'ES' ? 'España' : this.userData.nationality  || "No se ha definido",
          },
          {
            key: 'email',
            name: 'Email',
            value: this.userData.email,
            editable: true,
            editing: false,
          },
          {
            key: 'phone',
            name: 'Teléfono',
            value: this.userData.phone,
            editable: true,
            editing: false,
          },
          {
            key: 'address',
            name: 'Dirección',
            value: `${this.userData.addresses[0].via} ${this.userData.addresses[0].street} ${this.userData.addresses[0].number} ${this.userData.addresses[0].flat} ${this.userData.addresses[0].door} ${this.userData.addresses[0].other}` || "No se ha definido",
            editable: true,
            editing: false,
          },
          {
            key: 'location',
            name: 'Localidad',
            value: this.userData.addresses[0].city || "No se ha definido",
            editable: true,
            editing: false,
          },
          {
            key: 'province',
            name: 'Provincia',
            value: this.provinceData.province || "No se ha definido",
            editable: true,
            editing: false,
          },
          {
            key: 'zipCode',
            name: 'Código postal',
            value: this.userData.addresses[0].zip || "No se ha definido",
            editable: true,
            editing: false,
          },
        ],
      };       

      this.userInfo = {
        icon: 'badge',
        title: 'Dirección de envío',
        data: [
          {
            key: 'name',
            name: 'Nombre',
            value: this.userData.name,
          },
          {
            name: 'Primer apellido',
            value: this.userData.surname1 || "No se ha definido",
          },
          {
            name: 'Segundo apellido',
            value: this.userData.surname2 || "No se ha definido",
          },
          {
            key: 'birthdate',
            name: 'Fecha de nacimiento',
            value: this.datePipe.transform(
              this.userData.birthdate,
              'dd-MM-YYYY'
            ),
          },
          {
            key: 'nationality',
            name: 'Nacionalidad',
            value: this.userData.nationality === 'ES' ? 'España' : this.userData.nationality  || "No se ha definido",
          },
          {
            key: 'email',
            name: 'Email',
            value: this.userData.email,
            editable: true,
            editing: false,
          },
          {
            key: 'phone',
            name: 'Teléfono',
            value: this.userData.phone,
            editable: true,
            editing: false,
          },
          {
            key: 'identifier',
            name: 'Nº documento',
            value: `${this.userData.documentType}: ${this.userData.identifier}` || "No se ha definido",
          },
          {
            key: 'createdAt',
            name: 'Fecha creación',
            value: this.datePipe.transform(
              this.userData.createdAt || "No se ha definido",
              'dd-MM-YYYY'
            ),
          },
          {
            key: 'id',
            name: 'Id Usuario',
            value: this.userData.id,
          },
        ],
      };
      this.kycInfo = [
        {
          key: 'kyc',
          title: 'Nivel KYC',
          value: this.kycConvert(this.userData.state),
        },
        {
          key: 'validacion Dni',
          title: 'Validacion Dni',
          value: this.getDniState(this.userData.state),
          /* editable: true,
          editing: false */
        },
        /*         {
          key: 'categoríaPbc',
          title: 'Categoría PBC',
          value: 'No evaluado',
        },
        {
          key: 'riesgoPbc',
          title: 'Riesgo Pbc',
          value: 'No evaluado',
        }, */
      ];
    });
    setTimeout(() => {
      this.loader = false;
    }, 2000);
  }

  
  editField(item: any) {
    let body: Record<string, string> = {};
    body[item.key] = item.value;
    this.userService.editUser(this.userId, body).subscribe((res) => {
      this.getUserData();
    });
  }

  getProvinceList() {
    this.provinceList.cpList().subscribe((res) => {
      this.provinceData = getProvinceByCp(res, this.zipCode)!;
    });
  }

  toggleUserInfo(buttonType?: string) {
    if (buttonType == 'kyc') {
      if (this.showKyc == false) {
        this.showKyc = true;
      } else {
        this.showKyc = false;
      }
    }
    if (buttonType == 'failedOp') {
      if (this.showFailedOp == false) {
        this.showFailedOp = true;
      } else {
        this.showFailedOp = false;
      }
    }
    if (buttonType == 'back') {
      this.showFailedOp = false;
      this.showKyc = false;
    }
  }
}
