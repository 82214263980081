import { Component, Input } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UserData } from 'src/app/core/models/user/userData';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent {
  _userData!: UserData;
  public userId!: string;
  public userEmail!: any;
  public name = 'Angular 6';
  public htmlContent = '';

  //inputData viene de Host. Son los datos de company
  @Input() set userData(val: any) {
    if (!val) {
      return;
    }
    this._userData = val;
    this.userEmail = this._userData.email;
  }

  public config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Escribe tu mensaje',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Calibri',
    toolbarHiddenButtons: [['subscript', 'superscript', 'indent', 'outdent']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  constructor() {}
  ngOnInit(): void {}
}
