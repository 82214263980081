import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { FinancingService } from 'src/app/core/services/financing/financing.service';

@Component({
  selector: 'app-view-document-modal',
  templateUrl: './view-document-modal.component.html',
  styleUrls: ['./view-document-modal.component.scss']
})
export class ViewDocumentModalComponent implements OnInit {
  safeUrl: SafeResourceUrl | null = null;
  private blob: Blob | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { idFinancing: string, idDocument: string, format: string, name: string, url: string },
    public dialogRef: MatDialogRef<ViewDocumentModalComponent>,
    private financingService: FinancingService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.financingService.downloadDocument(this.data.idFinancing, this.data.idDocument).subscribe({
      next: (file: Blob) => {
        console.log(file)
        this.blob = file;
        this.safeUrl = this.sanitizeUrl(URL.createObjectURL(file));
      },
      error: (err) => {
        console.error('Error fetching document blob:', err);
        alert('No se pudo cargar el documento.');
      }
    });
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  downloadDocument(): void {
    if (this.blob) {
      const url = window.URL.createObjectURL(this.blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.data.name || 'documento';
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      alert('El documento no está disponible para descargar.');
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
