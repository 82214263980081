<div class="stepper">
  <mat-icon class="iconInfo" (click)="openDialog()">info</mat-icon>
  <div class="stepper-header">
    <div class="step" *ngFor="let step of steps; let i = index">
      <div class="circle" [class.completed]=" isStepCompleted(i)">
        <mat-icon>{{ isStepCompleted(i) ? 'check' : 'clear'}}</mat-icon>
      </div>
      <div class="line" [class.completed]="isStepCompleted(i)"></div>
      <p>{{step}}</p>
    </div>
  </div>
  <div class="stepper-content">
    <div [class.active]="selectedIndex === 0">
    </div>
    <div *ngFor="let step of steps; let i = index" [class.active]="selectedIndex === i">
      <p>{{ step }}</p>
    </div>
  </div>
</div>