<div class="document-viewer">
  <div class="header">
    <h2>Vista Previa del Documento</h2>
    <button (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>
  
  <ng-container [ngSwitch]="data.format.toLowerCase()">
    <iframe *ngSwitchCase="'pdf'" [src]="data.url" width="100%" height="500px"></iframe>
    <div class="image-container" *ngSwitchDefault>
      <img [src]="safeUrl" alt="Vista previa del documento" />
    </div>
  </ng-container>

  <button class="gold-button" (click)="downloadDocument()">Descargar</button>
</div>