<div class="general-container" *ngIf="cardData && cardData.length >= 1">
    <div class="section_header">
        <p class="section_title">{{_userData.name}}</p>
        <app-navigation-buttons [subLinks]="subLinks" [selectedLink]="selectedLink" (selected)="receiveSelectedLink($event)"></app-navigation-buttons>
    </div>
    <div>
         <ng-container *ngComponentOutlet="selectedLink.component; injector: dynamicInjector"></ng-container>
    </div>
   
</div>
<div class="no-cards" *ngIf="!hiddenNoCardsMsg">
    <p class="no-p" *ngIf="cardData && cardData.length == 0"> No hay tarjetas</p>

    <button class="gold-button" *ngIf="cardData && cardData.length == 0" (click)="toCreateCard()">Solicitar tarjeta</button>
    
</div>

<div *ngIf="requestedComponent">
  <ng-container *ngComponentOutlet="requestedComponent; injector: dynamicInjector"></ng-container>
</div>