<div class="tableMaterial">
  <table mat-table [dataSource]="dataSource" class="table-container">
    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef>Ver</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="getClient(element.id, element.name)"
      >
        <div class="icon-link">
          <mat-icon style="cursor: pointer">person</mat-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nombre empresa</th>
      <td mat-cell *matCellDef="let element">
        <p>{{ element.name }}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef>Marca</th>
      <td mat-cell *matCellDef="let element">
        <p>{{ element.brand }}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="vatNumber">
      <th mat-header-cell *matHeaderCellDef>CIF</th>
      <td mat-cell *matCellDef="let element">
        <p class="gold-p">{{ element.vatNumber }}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="cnae">
      <th mat-header-cell *matHeaderCellDef>CNAE</th>
      <td mat-cell *matCellDef="let element">
        <p>{{ element.cnae }}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="representative">
      <th mat-header-cell *matHeaderCellDef>Apoderado</th>
      <td mat-cell *matCellDef="let element">
        <p>{{ element.representative }}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="incorporationDate">
      <th mat-header-cell *matHeaderCellDef>Fecha creación</th>
      <td mat-cell *matCellDef="let element">
        <p>{{ element.createdAt | date : "dd-MM-yyyy" }}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>País</th>
      <td mat-cell *matCellDef="let element">
        {{ element.country === "ES" ? "España" : element.country }}
      </td>
    </ng-container>
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>Estado</th>
      <td mat-cell *matCellDef="let element">
        <div class="table_item">
          <p
            [ngClass]="{
              user_statusOk: element.state == 'CONTRACT_SIGNED',
              user_statusKo: !(element.state == 'CONTRACT_SIGNED')
            }"
          >
            {{ element.state == "CONTRACT_SIGNED" ? "Activo" : "Inactivo" }}
          </p>
        </div>
      </td>
      <ng-container matColumnDef="kyc">
        <th mat-header-cell *matHeaderCellDef>KYC</th>
        <td mat-cell *matCellDef="let element">
          {{ kycConvert(element.state) }}
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
    ></tr>
  </table>
  <p *ngIf="tableData()">No se han encontrado empresas</p>
</div>
