<div>
  <app-alerts *ngIf="alert" [alertMessage]="allertMessage" [alertStatus]="alertStatus"></app-alerts>
  <div>
    <h2>Firmantes</h2>
    <div class="button">
      <button class="gold-button" (click)="openUploadSignerModal()">+ Nuevo Firmante</button>
    </div>
    <div class="tableMaterial">
      <table *ngIf="!loader" mat-table [dataSource]="signersTableData" class="table-container" matSort >
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Completo</th>
          <td mat-cell *matCellDef="let element"><p>{{ element.fullName || 'N/A' }}</p></td>
        </ng-container>
  
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element"><p>{{ element.email || 'N/A' }}</p></td>
        </ng-container>
  
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Teléfono</th>
          <td mat-cell *matCellDef="let element"><p>{{ element.phone || 'N/A' }}</p></td>
        </ng-container>
  
        <ng-container matColumnDef="identifier">
          <th mat-header-cell *matHeaderCellDef>CIF/NIF</th>
          <td mat-cell *matCellDef="let element"><p>{{ element.identifier || 'N/A' }}</p></td>
        </ng-container>
  
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element"><button class="gold" (click)="openEditSignerModal(element)">Editar</button></td>
        </ng-container>
  
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element"><button class="delete-button" (click)="confirmDeleteSigner(element)">Eliminar</button></td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumnsSigners"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSigners;"></tr>
      </table>
  

      <div class="skeleton-container" *ngIf="loader">
        <ngx-skeleton-loader count="{{offset - 1}}" appearance="line" [theme]="{ height: '36px' }"></ngx-skeleton-loader>
      </div>

    </div>
  </div>
  <div class="signature">
    <h2>Documentos Firma Electrónica</h2>
    <div class="button">
      <button class="gold-button" (click)="openUploadSignerDocumentModal()">+ Subir Documento</button>
    </div>
    <div class="tableMaterial">
      <ng-container *ngIf="!loader">
        <table *ngIf="tableData.length > 0" mat-table [dataSource]="tableData" class="table-container" matSort>
          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef>Ver</th>
            <td mat-cell *matCellDef="let element">
              <div class="icon-link">
                <button mat-icon-button (click)="openViewDocumentModal(element)">
                  <mat-icon style="cursor:pointer">visibility</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="documentos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Documentos</th>
            <td mat-cell *matCellDef="let element">{{ element.documentos }}</td>
          </ng-container>
          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let element">{{ element.state || 'N/A' }}</td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Fecha</th>
            <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd/MM/yyyy' }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.state === 'Pendiente'">
                <button mat-button (click)="openSendDocumentModal(element)">Enviar</button>
                <button mat-button (click)="confirmDeleteSigningDocument(element)">Eliminar</button>
              </div>
              <div *ngIf="element.state === 'Enviado'">
                <button mat-button (click)="openDocumentDetailModal(element)">Detalle</button>
                <button mat-button (click)="cancelDocument(element)">Cancelar</button>
              </div>
              <div *ngIf="element.state === 'Cancelado'">
                <button mat-button (click)="confirmDeleteSigningDocument(element)">Eliminar</button>
              </div>
              <div *ngIf="element.state === 'Firmado'">
                <button mat-button (click)="openDocumentDetailModal(element)">Detalle</button>
              </div>
            </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumnsDocuments"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDocuments;"></tr>
        </table>
  
        <div *ngIf="tableData.length === 0" class="empty-message">
          <p>No hay documentos disponibles</p> 
        </div>
      </ng-container>
  
      <div class="skeleton-container" *ngIf="loader">
        <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '36px' }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>