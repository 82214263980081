<div class="company-data-content">
  <h1>Solicitar tarjeta</h1>
  <h2>Configura tu tarjeta</h2>
  <form class="company-data-form" [formGroup]="cardConfigForm">
    <div class="first">
      <div class="form-input">
        <h3>Selecciona un titular para tu tarjeta</h3>
        <div class="long_input">
          @if(companyProfile){
            <select formControlName="holder" (change)="onHolderChange($event)">
              @for (holder of holders; track $index) {              
                <option [value]="holder.name + ' ' + holder.surname1">
                {{holder.name}} {{holder.surname1}}
              </option>
              }
            </select>
          }@else if(individualProfile){
            <input formControlName="holder"/>
          }
        </div>
      </div>

      <div class="form-input">
        <h3>Configura un alias para tu tarjeta (OPCIONAL)</h3>
        <div class="long_input">
          <input formControlName="alias" placeholder="ej. Gastos de oficina" />
        </div>
        <mat-icon class="white">info</mat-icon>
      </div>

      <div class="form-input">
        <h3>Selecciona la cuenta asociada</h3>
        <div class="long_input">
          <select formControlName="associatedAccount" (change)="onAccountChange($event)">
            @for (account of accounts; track $index) {   
              <option [value]="account.iban">
                {{account.alias}} ({{account.iban}})
              </option>
            }
          </select>
        </div>
      </div>
    </div>
  </form>
  <div class="button-next">
    <button class="stroked-gold-button" type="button" routerLink="/empresas">
      Cancelar
    </button>
    <button class="gold-button" type="submit" [disabled]="cardConfigForm.invalid" (click)="nextStep()">
      Siguiente
    </button>
  </div>
</div>
