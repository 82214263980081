<div class="card-table-container" *ngIf="!showDetail">
  <div
    class="tableMaterial"
    *ngIf="loaded && dataSource && dataSource.filteredData.length > 0"
  >
    <table mat-table [dataSource]="dataSource">
      <!-- Columna titular -->
      <ng-container matColumnDef="cardholder">
        <th mat-header-cell *matHeaderCellDef>Titular</th>
        <td mat-cell *matCellDef="let group">
          <p>{{ group.name }}</p>
        </td>
      </ng-container>

      <!-- Columna tarjeta -->
      <ng-container matColumnDef="card">
        <th mat-header-cell *matHeaderCellDef>Tarjeta</th>
        <td mat-cell *matCellDef="let group">
          <ng-container>
            <div class="card-info">
              <mat-icon style="cursor: default">credit_card</mat-icon>
              <p>
                ****{{ group.maskedPAN?.slice(-4) }}
                {{ group.alias ? "/ " + group.alias : "" }}
                <span class="status">{{
                  group.status ? statusCard(group.status) : ""
                }}</span>
              </p>
            </div>
          </ng-container>
        </td>
      </ng-container>

      <!-- Columna tipo -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let group">
            <ng-container>
              <div class="card-info">
                <p>{{typeCard(group.type)}}</p>
              </div>
            </ng-container>
        </td>
      </ng-container>

      <!-- Columna cuenta asociada -->
      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef>Cuenta asociada</th>
        <td mat-cell *matCellDef="let group">
            <ng-container>
              <div class="card-info">
                <p>{{ group.account?.iban }}</p>
              </div>
            </ng-container>
        </td>
      </ng-container>

      <!-- Columna acceso detalle de la tarjeta -->
      <ng-container matColumnDef="detail">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let group">
            <ng-container>
              <div
                class="card-info"
                style="cursor: pointer; text-decoration: underline"
                (click)="openDetail(group.id, group.account?.id)"
              >
                <p>ver detalle</p>
                </div>
                </ng-container>
        </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let group; columns: displayedColumns"></tr>
    </table>
  </div>

  <!-- Skeleton loader -->
  <div class="skeleton-container">
    @if(!loaded){
      <ngx-skeleton-loader
      [count]="count"
      [theme]="{
        'border-radius': '8px',
        height: '40px',
        'background-color': '#E2E8F0'
      }"
      appearance="line"
    ></ngx-skeleton-loader>
    }
  </div>

  <!-- Sin tarjetas -->
  <div *ngIf="loaded && dataSource.filteredData.length === 0" class="not-cards">
    <h1>No hay tarjetas</h1>
  </div>
</div>

<!-- Detalle de la tarjeta-->
<app-cards-detail
  *ngIf="showDetail"
  [accountId]="accountId"
  [cardId]="cardId"
  (detailEmit)="detailEmit($event)"
></app-cards-detail>
