<fieldset class="fieldset">
  <legend class="legend" *ngIf="infoTable.icon || infoTable.title">
    <p class="info_title"><mat-icon *ngIf="infoTable.icon">{{infoTable.icon}}</mat-icon>{{infoTable.title}}</p>
  </legend>
  <table class="info_table">
    <ng-container *ngFor="let item of infoTable.data; let i = index">
      <tr>
        <th class="table_key">{{item.name}}</th>
        <td>
          <p *ngIf="!item.editing && !item.matIcon" class="td">{{item.value}}
            <mat-icon *ngIf="item.editable" (click)="showEdit(item)">edit</mat-icon>
            <mat-icon *ngIf="item.link" routerLink="{{item.link}}" style="color: rgb(70, 70, 226);"
              matTooltip="{{item.tooltip ? item.tooltip : null}}">open_in_new</mat-icon>
          </p>
          <p *ngIf="!item.editing && item.matIcon" class="td">
            <span *ngIf="item.value"> Sí </span>
            <span *ngIf="!item.value"> - </span>
          </p>

          <div class="td" *ngIf="item.editing">
        @if(item.isDateFuture){
          <mat-form-field>
            <input matInput [matDatepickerFilter]="filterFuture" [matDatepicker]="picker" [formControl]="editedField">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker [maxDate]="eighteenYearsAgo" [startAt]="eighteenYearsAgo" #picker></mat-datepicker>
          </mat-form-field>
            }@else if(item.isDatePast){
              <mat-form-field>
                <input matInput [matDatepickerFilter]="filterPast" [matDatepicker]="picker" [formControl]="editedField">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker [startAt]="eighteenYearsAgo" #picker></mat-datepicker>
              </mat-form-field>
            }
            @else{
              <mat-form-field>
                <input matInput [matDatepicker]="picker" [formControl]="editedField">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            }

            <div class="buttons_container">
              <mat-icon (click)="editField(item)">check</mat-icon>
              <mat-icon class="close" (click)="hideEdit(item)">close</mat-icon>

            </div>
          </div>
        </td>
      </tr>
    </ng-container>
  </table>
</fieldset>